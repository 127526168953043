import * as React from 'react';
import {Divider, Stack, Tooltip} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../components/platform/platformSpace";
import IconButton from "@mui/material/IconButton";
import {HelpOutline} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PlatformCheckbox from "../../components/platform/platformCheckbox";
import Button from "@mui/material/Button";

export default function CartSummary(props) {

    return (
        <div style={{width: "23vw"}}>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Subtotal
                </TotalsText>
                <TotalsText>
                    $150.00
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Shipping & taxes
                    <Tooltip title="Delete">
                        <IconButton>
                            <HelpOutline sx={{fontSize: "1rem"}} />
                        </IconButton>
                    </Tooltip>
                </TotalsText>
                <TotalsSubtext>
                    Calculated at next step
                </TotalsSubtext>
            </Stack>
            <PlatformSpace height={5}/>
            <CartDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <Typography variant="h7">
                    Total
                </Typography>
                <Stack spacing={2} justifyContent="flex-end" direction="row" alignItems="center">
                    <TotalsSubtext>
                        USD
                    </TotalsSubtext>
                    <Typography variant="h7">
                        $53.86
                    </Typography>
                </Stack>
            </Stack>
            <PlatformSpace height={30}/>
            <Typography variant="body5">
                You may enter order notes, special delivery instructions, or a gift message here.
            </Typography>
            <PlatformSpace height={30}/>
            <PlatformCheckbox
                label="I certify that I am over 21 years old and I acknowledge that an adult signature is required by law for all alcohol shipments."
                reversed
                inverted
                defaultValue={true}
                name="trackQuantity"
            />
            <PlatformSpace height={10}/>
            <CheckoutButton variant="contained">
                Checkout
            </CheckoutButton>
        </div>
    )
}

const CheckoutButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: 400
}));

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));