import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import PlatformSpace from "../../components/platform/platformSpace";
import useWindowSize from "../../components/hook/useWindowSize";
import {Divider, Stack} from "@mui/material";
import {styled, withStyles} from "@mui/styles";
import {Facebook, Instagram, Language, MoreHoriz, StarOutline, Twitter, Verified} from "@mui/icons-material";

const BRAND_PROFILE_STATS = [
    {label: "Followers", value: "122,583"},
    {label: "Created", value: "Mar 2023"},
    {label: "Following", value: "32"}
]

const BRAND_PRODUCT_STATS = [
    {label: "Seed Genetics", value: "23"},
    {label: "Clone Phenotypes", value: "11"},
]

export default function BreederBanner(props) {
    const bannerRef = useRef();
    const [height, setHeight] = useState(0);

    useWindowSize(() => updateHeight());

    useEffect(() => {
        setTimeout(() => updateHeight(), 50);
        setTimeout(() => updateHeight(), 170);
    }, []);

    const updateHeight = () => {
        setHeight(bannerRef.current.getBoundingClientRect().height);
    }

    const getBrandProfileStats = (brandProfileStat, idx) => {
        return (
            <React.Fragment>
                <span style={{fontSize: "1.2rem", color: "white"}}>{brandProfileStat.label} </span>
                <span style={{fontSize: "1.2rem", color: "white", fontWeight: 600}}>{brandProfileStat.value}</span>
                {((idx + 1) !== BRAND_PROFILE_STATS.length) && <span style={{fontSize: "1.2rem", color: "white", margin: "0 10px 0 10px"}}>·</span>}
            </React.Fragment>
        )
    }

    const getBrandProductStat = (brandProductStat) => {
        return (
            <div>
                <div style={{fontSize: "1.6rem", color: "white", fontWeight: 600, textTransform: "uppercase"}}>{brandProductStat.value}</div>
                <div style={{fontSize: "1rem", color: "rgb(112, 122, 131)", fontWeight: 400, textTransform: "lowercase"}}>{brandProductStat.label}</div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div style={{position: "relative", height: height + 40}}>
                <div style={{position: "absolute", overflow: "hidden", height: height - 15}}>
                    <BannerImage ref={bannerRef} src={`${process.env.PUBLIC_URL}/images/gasgiant/banner.png`}/>
                </div>
                <div style={{position: "relative", width: "95vw", margin: "auto"}}>
                    <img height={175} src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}
                         style={{border: "5px solid white", borderRadius: 15, marginTop: height - 165}}/>
                </div>
            </div>
            <div style={{width: "95vw", margin: "auto"}}>
                <Stack direction="row"
                       justifyContent="space-between"
                       alignItems="center">
                    <BrandTitle>
                        Gas Giant
                        <VerifiedIcon fontSize={"small"}/>
                    </BrandTitle>
                    <Stack justifyContent="flex-end"
                           direction="row"
                           spacing={2}
                           alignItems="center">
                        <Language/>
                        <Instagram/>
                        <Twitter/>
                        <Facebook/>
                        <Divider orientation="vertical" flexItem />
                        <StarOutline/>
                        <MoreHoriz/>
                    </Stack>
                </Stack>
                <PlatformSpace height={20}/>
                <div>
                    {BRAND_PROFILE_STATS.map((brandProfileStat, idx) => {
                        return getBrandProfileStats(brandProfileStat, idx);
                    })}
                </div>
                <PlatformSpace height={30}/>
                <Stack direction="row" spacing={3}>
                    {BRAND_PRODUCT_STATS.map((brandProductStat) => {
                        return getBrandProductStat(brandProductStat);
                    })}
                </Stack>
            </div>
        </React.Fragment>
    )
}

const StatTitle = styled('div')(({ theme }) => ({
    color: "rgb(112, 122, 131)",
    fontWeight: 500,
    fontSize: "1.0rem",
    textTransform: "lowercase",
    borderBottom: "none !important"
}))

const StatValue = styled('div')(({ theme }) => ({
    color: "white !important",
    fontWeight: "600 !important",
    fontSize: "1.2rem !important",
    borderBottom: "none !important"
}))

const VerifiedIcon = withStyles({
    root: {
        color: "rgb(32, 129, 226) !important",
        marginLeft: 5
    },
})(Verified);

const BrandTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));

const BannerImage = styled('img')(({ theme }) => ({
    width: "100vw"
}));
