import React, {useRef, useState} from "react";
import {Slide} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Bolt} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {styled} from "@mui/styles";
import PlatformCartSplitButtonWrapper from "./platformCartSplitButtonWrapper";

export default function PlatformCartSplitButton(props) {
    const {containerRef, hoverHandler = (hover) => {}, hover, absolute, tiled, height, width} = props;
    const innerRef = containerRef || useRef();
    const [buyNow, setBuyNow] = useState(false);
    const [innerHover, setInnerHover] = useState(false);
    const history = useHistory();

    const onBuyNowEnter = () => {
        setBuyNow(true);
        setInnerHover(false);

        hoverHandler(false);
    }

    const onBuyNowLeave = () => {
        setBuyNow(false);
        setInnerHover(true);

        hoverHandler(true);
    }

    const onMouseIn = () => {
        setInnerHover(true);
    }

    const onMouseOut = () => {
        setInnerHover(false);
    }

    const activeIn = hover !== null ? hover : innerHover;

    return (
        <PlatformCartSplitButtonWrapper relative={!absolute} width={width}>
            <SplitButtonContainer ref={containerRef || innerRef} buyNow={buyNow} absolute={absolute} onMouseEnter={onMouseIn}
                            onMouseLeave={onMouseOut}>
                {!buyNow &&
                    <Slide timeout={!absolute ? 0 : 200} direction="up" in={activeIn || !absolute} container={innerRef.current}>
                        <SplitButton height={height} width={width} tiled={tiled} onClick={() => history.push("/breeder/gasgiant/catalog/item/pinkruntz")}>
                            Add to cart
                        </SplitButton>
                    </Slide>
                }
                {!buyNow &&
                    <Slide timeout={!absolute ? 0 : 200} direction="up" in={activeIn || !absolute} container={innerRef.current}>
                        <BuyItNowIconButtonContainer height={height} width={width}>
                            <BuyItNowIconButton onMouseEnter={onBuyNowEnter} height={height} width={width}>
                                <Bolt/>
                            </BuyItNowIconButton>
                        </BuyItNowIconButtonContainer>
                    </Slide>
                }
                {buyNow &&
                    <Slide timeout={200} direction="left" in={buyNow} container={innerRef.current}>
                        <BuyItNowButton tiled={tiled} height={height} width={width} onMouseLeave={onBuyNowLeave} startIcon={<Bolt/>}>
                            Buy now
                        </BuyItNowButton>
                    </Slide>
                }
            </SplitButtonContainer>
        </PlatformCartSplitButtonWrapper>
    );
}

const BuyItNowButton = styled(Button)(({width, height, tiled}) => ({
    fontSize: "1rem !important",
    fontWeight: 600,
    backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))",
    display: "flex",
    height: height ? `${height}px !important` : "inherit",
    width: width ? `${width}px !important` : "inherit",
    color: "white !important",
    ...(tiled && {
        borderRadius: "0 0 12px 12px !important",
    }),
    zIndex: 10
}));

const BuyItNowIconButtonContainer = styled('div')(({height}) => ({
    position: "absolute",
    right: 0,
    bottom: 0,
    borderLeft: "1px solid rgb(59,42,84)",
    height: height ? `${height}px !important` : "inherit",
    zIndex: 9
}));

const BuyItNowIconButton = styled(IconButton)(({height}) => ({
    height: height ? `${height}px !important` : "inherit",
    width: 50,
    zIndex: 12
}));

const SplitButton = styled(Button)(({tiled, height, width}) => ({
    fontSize: "1rem !important",
    height: height ? `${height}px !important` : "inherit",
    width: width ? `${width}px !important` : "inherit",
    fontWeight: 600,
    backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))",
    color: "white !important",
    ...(tiled && {
        borderRadius: "0 0 12px 12px !important",
    }),
    zIndex: 1
}));

const SplitButtonContainer = styled('div')(({buyNow, absolute, width}) => ({
    overflow: "hidden !important",
    width: width ? `${width}px !important` : "inherit",
    backgroundImage: (absolute && buyNow || !absolute) ? "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))" : "inherit",
    position: "absolute",
    ...(absolute && {
        bottom: "0 !important",
    }),
    ...(!absolute && {
        borderRadius: 12,
    }),
    zIndex: 2
}));