import React from "react";
import {VictoryPie, VictoryTheme} from "victory";

export default function CatalogItemTerpenes(props) {
    const {width} = props;
    const {devicePixelRatio: ratio = 1} = window;

    return (
        <VictoryPie
            width={400}
            startAngle={90}
            endAngle={-90}
            theme={VictoryTheme.material}
            data={[
                { x: "Limonene", y: 35 },
                { x: "Humulene", y: 35 },
                { x: "Beta-caryophyllene", y: 40 },
                { x: "Myrcene", y: 55 }
            ]}
        />
    );
}