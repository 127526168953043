import React, {useState} from "react";
import PublicFooter from "./components/layout/publicFooter";
import PublicHeader from "./components/layout/publicHeader";

export const AppContext = React.createContext({});
export default AppContext;

const DEFAULT_OPTIONS = {
    backgroundComponent: null,
    footerComponent: PublicFooter,
    headerComponent: PublicHeader
};

export const AppContextProvider = ({children}) => {
    const [layoutOptions, setLayoutOptions] = useState(DEFAULT_OPTIONS);
    const [backgroundSize, setBackgroundSize] = useState(null);
    const [subscribed, setSubscribed] = useState(false);

    const onLayoutOptions = (updateOptions) => {
        setLayoutOptions({
            backgroundComponent: updateOptions.backgroundComponent || layoutOptions.backgroundComponent,
            footerComponent: updateOptions.footerComponent || layoutOptions.footerComponent,
            headerComponent: updateOptions.headerComponent || layoutOptions.headerComponent,
            ...updateOptions
        });
    }

    const onLayoutReset = () => {
        setLayoutOptions(DEFAULT_OPTIONS);
    }

    const onBackgroundSize = (backgroundSize) => {
        setBackgroundSize(backgroundSize);
    }

    return (
        <AppContext.Provider
            value={{
                onLayoutOptions,
                onLayoutReset,
                onBackgroundSize,
                layoutOptions,
                backgroundSize,
                subscribed,
                setSubscribed
            }}
        >
            <>{children}</>
        </AppContext.Provider>
    );
}