import React, {useEffect} from "react";
import {styled} from "@mui/styles";
import {Stack} from "@mui/material";
import {isMobile} from "react-device-detect";

export default function SplashIndex(props) {
    useEffect(()  => {
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundImage = "linear-gradient(to top, rgb(41, 31, 55), rgb(2, 2, 3))";
    }, []);

    return (
        <Stack justifyContent="center" direction="row" alignItems="center" spacing={2} sx={{height: "100vh", width: "100vw"}}>
            <Image src={`${process.env.PUBLIC_URL}/images/logo/logo_vp1.png`}/>
        </Stack>
    );
}

const Image = styled('img')(({ selected }) => ({
    cursor: "pointer",
    width: isMobile ? "80vw" : "50vw"
}));