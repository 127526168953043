import React, {useContext, useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Box from "@mui/material/Box";
import DropBackgroundVideo from "./dropBackgroundVideo";
import AppContext from "../../../appContext";
import DropFooter from "./dropFooter";

const SLIDES = [1, 2, 3, 4];

export default function DropRoadmap(props) {
    const appContext = useContext(AppContext);

    useEffect(() =>{
        appContext.onLayoutOptions({
            backgroundComponent: DropBackgroundVideo,
            footerComponent: DropFooter,
            video: "/images/drops/high.mp4"
        });
        return () => appContext.onLayoutReset();
    }, [])

    return (
        <Stack spacing={4}>
            { SLIDES.map((slide) => {
                return (
                    <Milestone component="div">
                        <Stack direction="row" spacing={4}>
                            <Image src={`${process.env.PUBLIC_URL}/images/drops/slide${slide}.jpeg`}/>
                            <Stack spacing={1}>
                                <Typography variant="body4" sx={{fontWeight: 600}}>
                                    Week of May 22
                                </Typography>
                                <Typography variant="h5">
                                    Physical Offerings
                                </Typography>
                                <Typography variant="body3" sx={{color: "rgb(112, 133, 131)"}}>
                                    Sloppy Studios partnered with Backhouse Music to provide non-exclusive commercial rights
                                    to the full song from the featured snippet on your FishBallerz mint. This means, if you
                                    so
                                    desired, you are free to monetize the use of the song so long as it is accompanied by
                                    your
                                    own original works. This use case is primarily relevant to other content creators or
                                    aspiring creators.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Milestone>
                )
            })}
        </Stack>
    );
}

const Image = styled('img')(({ theme }) => ({
    height: 150,
    pointerEvents: "none",
    borderRadius: 20
}));

const Milestone = styled(Box)(({ theme }) => ({
    border: "1px solid grey",
    borderRadius: 20,
    padding: 25
}));