import * as React from 'react';
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import PlatformCheckbox from "../../components/platform/platformCheckbox";
import {Divider, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import CheckoutCrumbs from "./checkoutCrumbs";
import CheckoutHeader from "./checkoutHeader";
import CheckoutFooter from "./checkoutFooter";
import CheckoutControls from "./checkoutControls";
import CheckoutAddressForm from "./checkoutAddressForm";

export default function CheckoutStep1Information(props) {
    return (
        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height: "90vh !important"}}>
            <div style={{width: "100%"}}>
                <CheckoutHeader/>
                <CheckoutCrumbs/>
                <PlatformSpace height={60}/>
                <Typography variant="h8">
                    Contact
                </Typography>
                <PlatformSpace height={10}/>
                <PlatformTextField placeholder="Email or mobile phone number" fullWidth/>
                <PlatformSpace height={10}/>
                <PlatformCheckbox
                    label="Email me with news and offers"
                    dense
                    reversed
                    defaultValue={true}
                    name="updateProfile"
                />
                <PlatformSpace height={30}/>
                <Typography variant="h8">
                    Shipping address
                </Typography>
                <PlatformSpace height={10}/>
                <CheckoutAddressForm/>
                <PlatformSpace height={30}/>
                <CheckoutControls/>
            </div>
            <CheckoutFooter/>
        </Stack>
    )
}

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));