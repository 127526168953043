import * as React from 'react';
import {Stack, Tooltip} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {HelpOutline} from "@mui/icons-material";
import Badge from "@mui/material/Badge";

export default function CheckoutSummary(props) {
    return (
        <div style={{width: "23vw"}}>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                    <Badge badgeContent={4} color="primary">
                        <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                    </Badge>
                    <div>
                        <Typography variant="body4">
                            Triple G Automatic
                        </Typography>
                        <br/>
                        <Typography variant="body5">
                            3x seeds
                        </Typography>
                    </div>
                </Stack>
                <TotalsText>
                    $150.00
                </TotalsText>
            </Stack>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                    <Badge badgeContent={9} color="primary">
                        <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                    </Badge>
                    <div>
                        <Typography variant="body4">
                            Triple G Automatic
                        </Typography>
                        <br/>
                        <Typography variant="body5">
                            3x seeds
                        </Typography>
                    </div>
                </Stack>
                <TotalsText>
                    $150.00
                </TotalsText>
            </Stack>
            <PlatformSpace height={30}/>
            <Stack direction="row" spacing={2} justifyContent="space-between" >
                <PlatformTextField placeholder="Discount code" fullWidth/>
                <ApplyButton variant="contained">Apply</ApplyButton>
            </Stack>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Subtotal
                </TotalsText>
                <TotalsText>
                    $150.00
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Shipping
                    <Tooltip title="Delete">
                        <IconButton>
                            <HelpOutline sx={{fontSize: "1rem"}} />
                        </IconButton>
                    </Tooltip>
                </TotalsText>
                <TotalsSubtext>
                    Calculated at next step
                </TotalsSubtext>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Estimated taxes
                    <Tooltip title="Delete">
                        <IconButton>
                            <HelpOutline sx={{fontSize: "1rem"}} />
                        </IconButton>
                    </Tooltip>
                </TotalsText>
                <TotalsText>
                    $0.00
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <Typography variant="h7">
                    Total
                </Typography>
                <Stack spacing={2} justifyContent="flex-end" direction="row" alignItems="center">
                    <TotalsSubtext>
                        USD
                    </TotalsSubtext>
                    <Typography variant="h7">
                        $53.86
                    </Typography>
                </Stack>
            </Stack>
        </div>
    )
}

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const ApplyButton = styled(Button)(({ theme }) => ({
    width: "140px !important",
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none"
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: 400
}));