import React, {useEffect, useState} from "react";
import HomeApi from "./homeApi";

export const HomeContext = React.createContext({});
export default HomeContext;

export const HomeContextProvider = ({children}) => {
    const [allCollection, setAllCollection] = useState([]);
    const [seedsCollection, setSeedsCollection] = useState([]);
    const [clonesCollection, setClonesCollection] = useState([]);
    const [breedersCollection, setBreedersCollection] = useState([]);
    const [auctionsCollection, setAuctionsCollection] = useState([]);

    useEffect(() => {
        onAllCollection();
        onSeedsCollection();
    }, [])

    const onAllCollection = () => {
        HomeApi.getCollection("EDITORS_PICKS_BREEDERS", null, (data) => {
            setAllCollection(data);
        })
    }

    const onSeedsCollection = () => {
        HomeApi.getCollection("EDITORS_PICKS_SEEDS", {}, (data) => {
            setSeedsCollection(data);
        })
    }

    const onClonesCollection = () => {
        HomeApi.getCollection("EDITORS_PICKS_CLONES", {}, (data) => {
            setClonesCollection(data);
        })
    }

    const onBreedersCollection = () => {
        HomeApi.getCollection("EDITORS_PICKS_BREEDERS", {}, (data) => {
            setBreedersCollection(data);
        })
    }

    const onAuctionsCollection = () => {
        HomeApi.getCollection("EDITORS_PICKS_AUCTIONS", {}, (data) => {
            setAuctionsCollection(data);
        })
    }

    return (
        <HomeContext.Provider
            value={{

            }}
        >
            <>{children}</>
        </HomeContext.Provider>
    );
}