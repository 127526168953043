import * as React from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "components/platform/platformSpace";
import Button from "@mui/material/Button";
import PlatformTextField from "components/platform/platformTextField";

export default function CatalogIntRangeFilter(props) {
    const {alwaysExpanded, topRow, bottomRow, title, icon, content} = props;

    return (
        <React.Fragment>
            <PlatformSpace height={15}/>
            <Stack justifyContent="space-between"
                   direction="row"
                   spacing={2}
                   alignItems="center">
                <PlatformTextField
                    centered
                    sx={{display: "flex"}}
                    placeholder="Min"
                />
                <FilterText>
                    To
                </FilterText>
                <PlatformTextField
                    centered
                    sx={{display: "flex"}}
                    placeholder="Max"
                />
            </Stack>
            <PlatformSpace height={20}/>
            <Button variant="contained">Apply</Button>
        </React.Fragment>
    )
}

const FilterText = styled('span')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white",
    textTransform: "lowercase",
    lineHeight: "3rem",
    margin: "0 8px 0 8px"
}));