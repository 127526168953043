import {createTheme} from '@mui/material/styles';

//https://mui.com/material-ui/customization/palette/
/*
--swiper-navigation-size: 44px;
--swiper-theme-color: #007aff;
--text-large: 48px;
--text-lh-large: 56px;
--text-lh-medium: 40px;
--text-lh-small: 32px;
--text-medium: 32px;
--text-small: 24px;
 */
const theme = createTheme({
    transitions: {
        duration: {
            enteringScreen: 2000,
            leavingScreen: 100,
        }
    },
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        },
        body1: {
            fontWeight: 700,
            color: "white"
        },
        body2: {
            fontWeight: 700,
            color: "white"
        },
        body3: {
            fontWeight: 600,
            color: "white",
            fontSize: "1.1rem",
            letterSpacing: 0.6
        },
        body4: {
            fontWeight: 600,
            color: "white",
            fontSize: "0.9rem",
            letterSpacing: 0.6
        },
        body5: {
            fontWeight: 500,
            color: "white",
            fontSize: "0.8rem",
            letterSpacing: 0.3
        },
        h3: {
            fontWeight: 700,
            color: "white"
        },
        h4: {
            fontWeight: 600,
            color: "white",
            textTransform: "none",
        },
        h5: {
            fontWeight: 600,
            color: "white",
            textTransform: "none",
        },
        h6: {
            fontWeight: 700,
            color: "white",
            textTransform: "none",
        },
        h7: {
            fontWeight: 600,
            color: "white",
            textTransform: "none",
            fontSize: "1.3rem",
        },
        h8: {
            fontWeight: 600,
            color: "white",
            textTransform: "none",
            fontSize: "1.1rem",
        },
        button: {
            fontWeight: 600,
            textTransform: "none",
            letterSpacing: 0,
            fontSize: "1.1rem"
        }
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    color: "rgb(112, 122, 131) !important",
                    fontWeight: 400
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "rgb(41, 31, 55) !important",
                        transition: "color .3s ease-out",
                        borderRadius: 0
                    },
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(59,42,84)",
                    borderRadius: "20px"
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                container: {
                    transitionDuration: 0
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    background: "none",
                    color: "white",
                    boxShadow: "none",
                    "&.Mui-expanded": {
                        marginTop: "0 !important"
                    },
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "rgb(59,42,84)",
                    marginTop: 15,
                    marginBottom: 15
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    "&.Mui-expanded": {
                        minHeight: "48px !important"
                    },
                },
                content: {
                    color: "white",
                    fontWeight: "600 !important",
                    fontSize: "1rem !important",
                    margin: 0,
                    "&.Mui-expanded": {
                        margin: "0 !important",
                    },
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: "0 !important"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: "100%",
                    borderRadius: 12,
                    fontWeight: 600,
                    fontSize: "1rem",
                    padding: 8,
                    backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))"
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    padding: 0,
                    border: "4px solid rgb(41, 31, 55)",
                    borderRadius: 12,
                    '& .MuiSvgIcon-root': {
                        color: "white !important"
                    },
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 600,
                    color: "black"
                },
                root: {
                    marginTop: "0 !important",
                    marginBottom: "0 !important"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "white",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: "rgb(172,186,197)"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: "white !important",
                        transition: "color .3s ease-out",
                    },
                    color: "rgb(112, 122, 131)",
                    //color: "rgba(227,204,255,0.56)",
                    fontWeight: 600,
                    fontSize: "1.6rem !important",
                    "&.Mui-selected": {
                        transition: "color .3s ease-out",
                        color: "white",
                    },
                },
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderWidth: "0 !important",
                },
                root: {
                    padding: 0,
                    borderRadius: 8,
                    backgroundColor: "white"
                },
                input: {
                    borderRadius: 8,
                    padding: "10px !important",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                }
            }
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins"
                }
            }
        }
    },
    // palette: {
    //   primary: {
    //     main: '#556cd6',
    //   },
    //   secondary: {
    //     main: '#19857b',
    //   },
    //   error: {
    //     main: red.A400,
    //   },
    // },
});

export default theme;