import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import styles from "./platformStyles";
import InputAdornment from "@mui/material/InputAdornment";
import PlatformFormContext from "./platformFormContext";
import mergeStyles from "../util/mergeStyles";

const useStyles = makeStyles(styles);

export default function PlatformTextField(props) {
    const [value, setValue] = useState("");
    const [valid, setValid] = useState(true);
    const {disabled, onChange, centered, fullWidth, name, label, email, errorText, helperText, endAdornment, required,
        defaultValue, inverted, inline, multiline, startAdornment, unbound, groupBy, inputRef, ...allProps} = props;
    const context = useContext(PlatformFormContext);
    const classes = useStyles();

    // =================== Begin Platform Logic

    useEffect(() => {
        if (context && context.addField) {
            context.addField(name, {
                name: name,
                groupBy: groupBy,
                setValue: setFormValue,
                unbound: unbound,
                setDefaultValue: setDefaultValue,
                validate: onValidate,
                changeHandler: changeHandler
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
    }, []);

    useEffect(() => {
        if (disabled) {
            setValue("");
            context.removeField(name);
        }
    }, [disabled])

    useEffect(() => {
        if (context != null) {
            //context.form.current.setValue(name, defaultValue); // DOESNT WORK!!!!
        }
        setValue(defaultValue || "");
    }, [defaultValue])

    const setFormValue = (value) => {
        if (value === null || value === undefined) {
            //console.log('name', name);
            //console.log('value', value);
        } else {
            setValue(value);
        }
    }

    const setDefaultValue = (defaultValue) => {
        if (context != null) {
            context.form.current.setValue(name, defaultValue, groupBy);
        }
        setValue(defaultValue || "");
    }

    const onValidate = () => {
        const valueToCheck = context.form.current.getValue(name);

        if (unbound) {
            return true;
        }

        if (!required) {
            setValid(true);
            return true;
        }

        if (valueToCheck === null || valueToCheck === undefined || valueToCheck === "undefined") {
            setValid(false);
            return false;
        }

        if (email) {
            let isValid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(valueToCheck);
            setValid(isValid);
            return isValid;
        }

        if (allProps.type === "number-step") {
            const step = allProps.step || 5;
            if (valueToCheck % step !== 0) {
                setValid(false);
                return false;
            }
        }

        setValid(true);
        return true;
    }

    const blurHandler = (event) => {
        if (context != null) {
            context.form.current.onBlur(name, event);
        }
        //setReadOnly(true);
    }

    const changeHandler = (event) => {
        let value = event.target.value;
        if (allProps.type === "number" && value) {
            if (allProps.max && parseFloat(value) > allProps.max) {
                value = allProps.max;
            }
            if (allProps.min && parseFloat(value) < allProps.min) {
                value = allProps.min;
            }
        }
        setValue(value);
        innerChangeHandler(event, value);
    };

    const innerChangeHandler = (event, value) => {
        if (context != null) {
            context.form.current.onChange(name, value, false, groupBy);
        }

        if (typeof onChange === "function") {
            onChange(name, event, groupBy)
        }
    }

    // =================== End Platform Logic

    let inputProps = {
        "aria-label": "outlined-input",
        "autoComplete": "new-password",
        min: allProps.min || 10,
        max: allProps.max || 10000,
        sx: {
            "&": {
                textAlign: centered ? "center" : "inherit",
            },
        }
    }

    if (disabled) {
        inputProps.readOnly = Boolean(true);
    }

    return (
        <div style={{width: fullWidth ? "100%" : "inherit"}}>
            {label &&
                <InputLabel className={classes.platformFormLabel}
                            style={mergeStyles(inverted && { color: "white" }, inline && { display: "inline-block" })}
                            htmlFor="outlined-input">
                    {`${label ? label : ""}${required ? '*' : ''}`}
                </InputLabel>
            }
            <FormControl className={classes.platformFormControl} fullWidth={fullWidth} variant="outlined">
                <OutlinedInput
                    id="outlined-input"
                    sx={{
                        '& .MuiInputAdornment-root': {
                            paddingTop: 1,
                            paddingLeft: 1,
                            '& .MuiSvgIcon-root': {
                                color: "rgb(100, 109, 117) !important"
                            },
                        },
                    }}
                    fullWidth={fullWidth}
                    multiline={multiline}
                    inputRef={inputRef}
                    notched={false}
                    {...allProps}
                    error={!valid}
                    value={value} // Don't put || "" here it breaks numbers
                    //onFocus={focusHandler}
                    onBlur={blurHandler}
                    onChange={changeHandler}
                    startAdornment={startAdornment ? <InputAdornment style={{marginRight: 0}} position="start"><span>{startAdornment}</span></InputAdornment> : null}
                    endAdornment={endAdornment ? <InputAdornment position="start"><span>{endAdornment}</span></InputAdornment> : null}
                    aria-describedby="outlined-helper-text"
                    //inputComponent={NumberFormatCustom}
                    inputProps={inputProps}
                />
                {(valid && helperText) && <FormHelperText style={mergeStyles(inverted && {color: "white"})} id="outlined-helper-text">{helperText}</FormHelperText>}
                {(!valid && helperText) && <FormHelperText style={{color: "red"}}>{errorText}</FormHelperText>}
            </FormControl>
        </div>
    );
};