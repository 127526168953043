import * as React from 'react';
import {useContext, useEffect, useRef} from 'react';
import PlatformForm from "../../components/platform/platformForm";
import DropsBanner from "./dropsBanner";
import PlatformTabs from "../../components/platform/platformTabs";
import PlatformSpace from "../../components/platform/platformSpace";
import DropsCatalog from "./dropsCatalog";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import AppContext from "../../appContext";

export default function DropsHome(props) {
    const formRef = useRef();
    const appContext = useContext(AppContext);

    useEffect(() =>{
        return () => appContext.onLayoutReset();
    }, [])

    return (
        <PlatformForm ref={formRef}>
            <PageTitle>
                <Typography variant="h3">Drops</Typography>
            </PageTitle>
            <PlatformSpace height={10}/>
            <DropsBanner/>
            <PlatformSpace height={30}/>
            <PlatformTabs fontSize="1.1rem" tabs={[
                {label: "Active & Upcoming", content: <DropsCatalog/>},
                {label: "Past", content: <DropsCatalog/>},
            ]}/>
        </PlatformForm>
    )
}

const PageTitle = styled('h1')(({ theme }) => ({
    padding: "30px 0 30px 30px",
    margin: "auto"
}));