import * as React from 'react';
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../components/platform/platformSpace";
import IconButton from "@mui/material/IconButton";
import {Add, ArrowBackIos, Remove} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";

export default function CartItems(props) {

    const onBack = () => {
    }

    return (
        <div style={{width: "100%"}}>
            <Typography variant="h8">
                Your Cart
            </Typography>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="flex-start">
                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="flex-start">
                    <Badge badgeContent={4} color="primary">
                        <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                    </Badge>
                    <div style={{marginTop: 15}}>
                        <Typography variant="body1">
                            Triple G Automatic
                        </Typography>
                        <Stack spacing={1} justifyContent="flex-start" direction="row" alignItems="center">
                            <IconButton>
                                <Remove sx={{fontSize: "1.1rem", color: "#5492D9FF"}}/>
                            </IconButton>
                            <Typography variant="body4">
                                1
                            </Typography>
                            <IconButton>
                                <Add sx={{fontSize: "1.1rem", color: "#5492D9FF"}}/>
                            </IconButton>
                            {/*<ChangeLink variant="body5">*/}
                            {/*    Save for later*/}
                            {/*</ChangeLink>*/}
                        </Stack>
                    </div>
                </Stack>
                <Stack spacing={1} justifyContent="flex-start" alignItems="flex-end">
                    <TotalsText style={{marginTop: 15}}>
                        $150.00
                    </TotalsText>
                    <div>
                        <ChangeLink variant="body5">
                            Remove
                        </ChangeLink>
                    </div>
                </Stack>
            </Stack>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="flex-start">
                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="flex-start">
                    <Badge badgeContent={7} color="primary">
                        <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                    </Badge>
                    <div style={{marginTop: 15}}>
                        <Typography variant="body1">
                            Triple G Automatic
                        </Typography>
                        <Stack spacing={1} justifyContent="flex-start" direction="row" alignItems="center">
                            <IconButton>
                                <Remove sx={{fontSize: "1.1rem", color: "#5492D9FF"}}/>
                            </IconButton>
                            <Typography variant="body4">
                                1
                            </Typography>
                            <IconButton>
                                <Add sx={{fontSize: "1.1rem", color: "#5492D9FF"}}/>
                            </IconButton>
                            {/*<ChangeLink variant="body5">*/}
                            {/*    Save for later*/}
                            {/*</ChangeLink>*/}
                        </Stack>
                    </div>
                </Stack>
                <Stack spacing={1} justifyContent="flex-start" alignItems="flex-end">
                    <TotalsText style={{marginTop: 15}}>
                        $150.00
                    </TotalsText>
                    <div>
                        <ChangeLink variant="body5">
                            Remove
                        </ChangeLink>
                    </div>
                </Stack>
            </Stack>
            <PlatformSpace height={60}/>
            <CartLink onClick={onBack}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={1}>
                    <div>Continue browsing</div>
                </Stack>
            </CartLink>
        </div>
    )
}

const BackArrow = styled(ArrowBackIos)(({ theme }) => ({
    fontSize: "0.8rem !important",
    color: "#5492D9FF !important",
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ChangeLink = styled(Typography)(({  }) => ({
    color: "#5492D9FF !important",
    textDecoration: "underline",
    cursor: "pointer"
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 125,
    height: 125,
    borderRadius: "12px",
    pointerEvents: "none"
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: 400
}));

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));