import React, {useContext} from "react";
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import {AccountCircleOutlined, ShoppingCartOutlined} from "@mui/icons-material";
import PlatformSearchField2 from "../../components/platform/platformSearchField2";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import PublicContext from "./publicContext";

export default function PublicHeader(props) {
    const {offset} = props;
    const history = useHistory();
    const {setShowCart} = useContext(PublicContext);

    const onShowCart = () => {
        setShowCart(true);
    }

    const onHome = () => {
        history.push("/");
    }

    const onDrops = () => {
        history.push("/drops");
    }

    return (
        <HeaderContainer>
            <Header scrolled={offset > 50}>
                <Stack direction="row" spacing={3} sx={{padding: "0 30px 0 30px", height: 50}}
                       justifyContent="center" alignItems="center">
                    <Image onClick={onHome} src={`${process.env.PUBLIC_URL}/images/logo/logo_vp1.png`}/>
                    <Divider orientation="vertical" flexItem />
                    <div style={{minWidth: 100}}>
                    <Link onClick={onDrops}>
                        <Typography variant="body1">
                            Live Drops
                        </Typography>
                    </Link>
                    </div>
                    <PlatformSearchField2 fullWidth/>
                    <IconButton sx={{height: "95%"}}>
                        <AccountCircleOutlined sx={{color: "white"}} fontSize="large" />
                    </IconButton>
                    <IconButton sx={{height: "95%"}} onClick={onShowCart}>
                        <ShoppingCartOutlined sx={{color: "white"}} fontSize="large" />
                    </IconButton>
                </Stack>
            </Header>
        </HeaderContainer>
    )
}

const Link = styled('div')(({ selected }) => ({
    cursor: "pointer",
}));

const Image = styled('img')(({ selected }) => ({
    cursor: "pointer",
    height: "95%"
}));

const HeaderContainer = styled('div')(({ selected }) => ({
    width: "100vw",
    margin: "0 auto 0 auto",
}));

const Header = styled('div')(({ scrolled }) => ({
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    transition: "background .25s ease-out",
    width: "100vw",
    zIndex: 5000,
    padding: "15px 0 15px 0",
    ...(scrolled && {
        backgroundColor: "rgb(41, 31, 55) !important",
    }),
}));