import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/styles";
import {Stack} from "@mui/material";
import PlatformSpace from "../../components/platform/platformSpace";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function BreederSubscription(props) {
    const {title, price, benefits = []} = props;
    const [expanded, setExpanded] = React.useState(false);
    const history = useHistory();

    const onShowMore = () => {
        setExpanded(!expanded);
    };

    const onJoin = () => {
        history.push("/breeder/gasgiant/subscribe")
    }

    const getContent = () => {
        return (
            <React.Fragment>
                {benefits.map((benefit) => {
                    return (
                        <div>
                            <Typography variant="body4">
                                ★ {benefit.title}
                            </Typography>
                            <PlatformSpace height={10}/>
                            <Typography variant="body5">
                                {benefit.content}
                            </Typography>
                            <PlatformSpace height={10}/>
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }

    return (
        <Card sx={{width: "18vw"}}>
            <CardContent>
                <PlatformSpace height={20}/>
                <Stack justifyContent="center" alignItems="center">
                    <Typography variant="body1">
                        {title}
                    </Typography>
                    <PlatformSpace height={15}/>
                    <Typography variant="h4">
                        {price}
                    </Typography>
                    <Typography variant="body4">
                        monthly price
                    </Typography>
                    <PlatformSpace height={20}/>
                    <JoinButton variant="contained" onClick={onJoin}>
                        Join
                    </JoinButton>
                    <PlatformSpace height={10}/>
                    <Typography variant="body5">
                        <i>All the rewards above plus:</i>
                    </Typography>
                </Stack>
                <PlatformSpace height={20}/>
                {!expanded &&
                <MoreContainer>
                    <MoreContent>
                        {getContent()}
                    </MoreContent>
                    <MoreOverlay/>
                    <MoreLink onClick={onShowMore}>
                        Show more
                    </MoreLink>
                </MoreContainer>
                }
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {getContent()}
                </Collapse>
            </CardContent>
            <CardActions>
                <CardStack justifyContent="center" direction="row" alignItems="center" spacing={2}>
                    <DiscordIcon src={`${process.env.PUBLIC_URL}/svg/discord.svg`}/>
                    <Typography paragraph>
                        Includes Discord benefits
                    </Typography>
                </CardStack>
            </CardActions>
        </Card>
    )
}

/*
Subscriptions
+ access to drops
+ early access to seeds/presales
+ discounts
+ ask the grower, DM access
+ group chat access
+ giveaways
+ access to discrd channel
+ strain cards
+ access to auctions
+ loot boxes
+ merch
 */

const MoreContainer = styled("div")(({ theme }) => ({
    position: "relative",
    height: 225,
    overflow: "hidden !important",
}));

const MoreOverlay = styled("div")(({ theme }) => ({
    position: "absolute",
    backgroundImage: "linear-gradient(to top, rgb(59, 42, 84), rgba(59, 42, 84, 0), rgba(59, 42, 84, 0), rgba(59, 42, 84, 0))",
    height: 225,
    width: "100%",
    top: 0,
    left: 0,
}));

const MoreContent = styled("div")(({ theme }) => ({
    position: "absolute",
    height: 225,
    top: 0,
    left: 0,
}));

const MoreLink = styled("div")(({ theme }) => ({
    position: "absolute",
    cursor: "pointer",
    bottom: 0,
    width: "300",
    left: "50%",
    fontSize: "1rem",
    fontWeight: "500",
    color: "white",
    textDecoration: "underline",
    transform: "translate(-50%, 0)"
}));

const CardStack = styled(Stack)(({ theme }) => ({
    padding: 20
}));

const DiscordIcon = styled("img")(({ theme }) => ({
    height: 35,
    color: "white !important",
}));

const JoinButton = styled(Button)(({ theme }) => ({
    height: 55,
    width: "95%",
    backgroundImage: "none !important",
}));