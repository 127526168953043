import * as React from 'react';
import {useContext, useRef} from 'react';
import PlatformForm from "../../components/platform/platformForm";
import BreederBanner from "./breederBanner";
import PlatformTabs from "../../components/platform/platformTabs";
import BreederSeeds from "./breederSeeds";
import BreederAuctions from "./breederAuctions";
import BreederDrops from "./breederDrops";
import AppContext from "../../appContext";
import BreederSubscriptions from "./breederSubscriptions";

export default function BreederHome(props) {
    const formRef = useRef();
    const {subscribed} = useContext(AppContext);

    return (
        <PlatformForm ref={formRef}>
            <BreederBanner/>
            <PlatformTabs scrollTo={650} fontSize="1.4rem" centered tabs={[
                {label: "Join the community", content: <BreederSubscriptions/>},
                {label: "Seeds", content: <BreederSeeds/>},
                {label: "Clones", content: <BreederSeeds/>},
                {label: "Auctions", content: <BreederAuctions/>},
                {label: "Drops", content: <BreederDrops/>},
            ]}/>
        </PlatformForm>
    )
}