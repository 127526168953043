import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CatalogItemContextProvider} from "./catalogItemContext";
import CatalogItemHome from "./catalogItemHome";

export default function CatalogItemIndex(props) {
    return (
        <PublicLayout>
            <CatalogItemContextProvider>
                <CatalogItemHome/>
            </CatalogItemContextProvider>
        </PublicLayout>
    );
}