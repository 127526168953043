import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import styles from "./platformStyles";
import cx from "classnames";
import PlatformFormContext from "./platformFormContext";
import mergeStyles from "../util/mergeStyles";
import _ from "lodash";

const useStyles = makeStyles(styles);

export default function PlatformSelect(props) {
    const [value, setValue] = useState("");
    const [valid, setValid] = useState(true);
    const {onChange, size, fullWidth, name, label, email, errorText, helperText, adornment, required, options,
        defaultValue, children, inverted, inline, disabled, width, setValueHandler, unbound, optionsHandler,
        ...allProps} = props;
    const classes = useStyles();
    const context = useContext(PlatformFormContext);

    useEffect(() => {
        if (context) {
            context.addField(name, {
                name: name,
                setValue: setFormValue,
                setDefaultValue: () => {},
                unbound: unbound,
                validate: onValidate,
                changeHandler: changeHandler
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
    }, []);

    useEffect(() => {
        if (disabled && !defaultValue) {
            setValue("");
            context.removeField(name);
        }
    }, [disabled])

    const setFormValue = (formValue) => {
        const innerSetFormValue = (value, opts) => {
            if (!value) {
                setValue("");
            } else if (typeof setValueHandler === "function") {
                const valueToSet = setValueHandler(value, opts);
                setValue(valueToSet);
            } else {
                setValue(value);
            }
        }

        if (typeof optionsHandler === "function") {
            optionsHandler((optionsResult) => {
                innerSetFormValue(formValue, optionsResult);
            })
        } else {
            innerSetFormValue(formValue, options);
        }
    }

    // const useSelectStyles = makeStyles(theme => ({
    //     root: {
    //         flexGrow: 1,
    //         backgroundColor: "white",
    //         marginRight: 20,
    //         width: 340,
    //         borderBottom: "0 solid rgba(0, 0, 0, 0.0)",
    //         borderRadius: 4,
    //         marginTop: 5
    //     },
    // }));
    // const selectStylesClasses = useSelectStyles();

    const renderMenu = () => {
        let retVal = [];
        (options || []).forEach((option, idx) => {
            retVal.push(
                <MenuItem key={idx} value={option.value}>
                    <ListItemText
                        primary={option.label}
                        classes={{
                            primary: classes.platformListItemPrimary,
                            root: classes.platformListItemRoot
                        }}
                    />
                </MenuItem>
            );
        });
        return retVal;
    };

    const onValidate = () => {
        const valueToCheck = context.form.current.getValue(name);

        if (!required) {
            setValid(true);
            return true;
        }

        if (_.isEmpty(valueToCheck)) {
            setValid(false);
            return false;
        }

        setValid(true);
        return true;
    }

    const blurHandler = (event) => {
        if (context != null) {
            context.form.current.onBlur(name, event);
        }
    }

    const changeHandler = (event) => {
        const value = event.target.value;
        setValue(value);

        if (context != null) {
            context.form.current.onChange(name, value);
        }

        if (typeof onChange === "function") {
            onChange(name, value)
        }
    };

    const setDefaultValue = (defaultValue) => {
        if (context != null) {
            context.form.current.setValue(name, defaultValue);
        }
        setValue(defaultValue || "");
    }

    let combinedClassName = cx({
        [classes.platformSelect]: true,
        [classes.platformSelectMd]: size === "md",
        [classes.platformInputFullWidth]: fullWidth
    });

    let platformFormContainerCombined = cx({
        [classes.platformFormContainer]: !inline,
        [classes.platformFormContainerInline]: inline
    });

    return (
        <div className={platformFormContainerCombined} style={{marginTop: 3}}>
            <InputLabel className={classes.platformFormLabel} style={
                mergeStyles(inverted && { color: "white" }, inline && { display: "inline-block" })}
                        htmlFor="outlined-input">{`${label ? label : ""}${required ? '*' : ''}`}</InputLabel>
            <FormControl className={classes.platformFormControl} variant="outlined">
                <Select
                    labelId="select-label"
                    id="select"
                    classes={{
                        root: classes.platformSelect
                    }}
                    disabled={disabled}
                    //value={age}
                    //onChange={handleChange}
                    input={<OutlinedInput
                        id="outlined-input"
                        {...allProps}
                        error={!valid}
                        value={value}
                        onBlur={blurHandler}
                        onChange={changeHandler}
                        //onChange={changeHandler(name)}
                        aria-describedby="outlined-helper-text"
                        inputProps={{
                            "aria-label": "outlined-input",
                        }}
                        classes={{
                            root: combinedClassName
                        }}
                    />}
                >
                    {renderMenu()}
                </Select>
                {valid && <FormHelperText
                    id="outlined-helper-text">{helperText}</FormHelperText>}
                {!valid && <FormHelperText style={{color: "red"}}>{errorText}</FormHelperText>}
            </FormControl>
        </div>
    );
};