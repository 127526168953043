import * as React from 'react';
import {useState} from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {styled} from "@mui/styles";


export default function PlatformSliderButtons(props) {
    const {tabs, tabsWidth, tabWidth, centered, scrollTo} = props;
    const [value, setValue] = useState("0");

    const onTabChange = (event, newValue) => {
        setValue(newValue);

        setTimeout(() => {
            window.scrollTo({
                top: scrollTo - 120,
                behavior: "smooth",
            });
        }, 50)
    };

    return (
        <div>
            <TabContext value={value}>
                <TabButtons onChange={onTabChange} aria-label="Top charts" centered={centered} variant="standard"
                            tabsWidth={tabsWidth}
                            TabIndicatorProps={{
                                sx: {
                                    boxShadow: "inset 0 0 0 4px #1c132a",
                                    background: "rgba(255, 255, 255, 0.2)",
                                    borderRadius: 3,
                                    height: "100%",
                                    color: "black"
                                }
                            }}
                >
                    {tabs.map((tab, idx) => (
                        <TabButton tabWidth={tabWidth} tabs={tabs} key={`${tab.label}-${idx}`} label={tab.label} value={String(idx)} />
                    ))}
                </TabButtons>
            </TabContext>
        </div>
    )
}

const TabButtons = styled(TabList)(({ tabsWidth, tabs }) => ({
    backgroundColor: "#1c132a",
    borderRadius: 12,
    width: tabsWidth,
}));

const TabButton = styled(Tab)(({ fontSize, tabWidth }) => ({
    '&.MuiTab-root': {
        fontSize: "1rem !important",
    },
    width: tabWidth,
    ...(fontSize && {
        fontSize: fontSize
    }),
}));