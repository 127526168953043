import React, {useContext} from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import AppContext from "../../../appContext";

export default function DropFooter(props) {
    const appContext = useContext(AppContext);

    return (
        <Footer>
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Image src={`${process.env.PUBLIC_URL}/images/drops/slide1.jpeg`}/>
                    <DropTitle>
                        FishBallerz
                    </DropTitle>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <CalendarButton variant="outlined">
                        Add to calendar
                    </CalendarButton>
                    <ReminderButton variant="outlined">
                        Set a reminder
                    </ReminderButton>
                </Stack>
            </Stack>
        </Footer>
    );
}

const Image = styled('img')(({ theme }) => ({
    height: 50,
    pointerEvents: "none",
    borderRadius: 12
}));

const DropTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "black"
}));

const Footer = styled('div')(({ theme }) => ({
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    padding: "10px 60px 10px 60px"
}));

const CalendarButton = styled(Button)(({ theme }) => ({
    border: "1px solid rgba(0, 0, 0, .1)",
    borderRadius: 12,
    width: "200px !important",
    padding: "10px !important",
    backgroundImage: "none !important"
}));

const ReminderButton = styled(Button)(({ theme }) => ({
    border: "1px solid rgba(0, 0, 0, .1)",
    borderRadius: 12,
    width: "200px !important",
    padding: "10px !important",
    backgroundImage: "none !important",
    backgroundColor: "#1976d2 !important",
    color: "white !important"
}));