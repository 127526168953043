import * as React from 'react';
import {useState} from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {styled} from "@mui/styles";

export default function PlatformNetflixSliderItem(props) {
    const {title, image, itemStyle, content, size} = props;
    const [hover, setHover] = useState(false);

    const onMouseEnter = () => {
        setHover(true);
    }

    const onMouseLeave = () => {
        setHover(false);
    }

    return (
        <ImageListItem key={image} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <TileContainer>
                <ImageContainer hover={hover}>
                    <Image src={image} size={size} alt={title} />
                </ImageContainer>
            </TileContainer>
            {(!itemStyle || itemStyle === "feature") &&
                <StyledImageListItemBar
                    sx={{
                        backgroundImage: "linear-gradient(to top, rgba(5, 5, 5, 0.8), rgba(0, 0, 0, 0.00)) !important",
                    }}
                    title={<ListItemBarTitle>{title}</ListItemBarTitle>}
                    position="bottom"
                />
            }
            {(itemStyle === "section") &&
                <StyledImageListItemBar
                    sx={{
                        backgroundImage: "linear-gradient(to top, rgba(5, 5, 5, 0.9), rgba(0, 0, 0, 0.5)) !important",
                    }}
                    title={<ListItemBarTitle>{title}</ListItemBarTitle>}
                    subtitle={content}
                    position="bottom"
                />
            }
        </ImageListItem>
    )
}

const TileContainer = styled('div')(({ theme }) => ({
    zIndex: 2,
    cursor: "pointer",
    boxShadow: "rgba(183, 132, 255, 0.25) 0px 6px 25px 0px",
    borderRadius: 20,
    width: "100%",
    overflow: "hidden"
}));

const ImageContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    borderRadius: "12px 12px 0 0",
    transition: ".3s ease-in-out",
    transform: !hover ? "scale(1)" : "scale(1.1)"
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: size || 175,
    width: "100%",
    borderRadius: 20,
    pointerEvents: "none"
}));

const ListItemBarTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.1rem",
}));

const StyledImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
    background: "none !important",
    borderRadius: "0 0 20px 20px",
    "& .MuiImageListItemBar-title": {
        color: "white",
        fontSize: "1.0rem",
        fontWeight: 500
    },
}));