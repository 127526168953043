import React, {useContext, useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../../components/platform/platformSpace";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import {Verified} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DropBackgroundVideo from "./dropBackgroundVideo";
import AppContext from "../../../appContext";

const SLIDES = [1, 2, 3, 4];

export default function DropInfo(props) {
    const appContext = useContext(AppContext);
    const [thumbnailSelected, setThumbnailSelected] = useState(0);

    useEffect(() =>{
        appContext.onLayoutOptions({
            backgroundComponent: DropBackgroundVideo,
            video: "/images/drops/high.mp4"
        });
        return () => appContext.onLayoutReset();
    }, [])

    const onSelectThumbnail = (idx) => {
        setThumbnailSelected(idx);
    }

    return (
        <React.Fragment>
            <Grid2 container>
                <Grid2 item md={5}>
                    <PlatformSpace height={20}/>
                    <Typography variant="h5">
                        About this collection
                    </Typography>
                    <PlatformSpace height={20}/>
                    <Stack direction="row" spacing={2}
                           justifyContent="flex-start"
                           alignItems="center">
                        <BrandLogo height={65} src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}/>
                        <div>
                            <DropTitle>Shredderz<VerifiedIcon sx={{fontSize: "0.8rem"}}/></DropTitle>
                            <DropSubTitle>Ethereum</DropSubTitle>
                        </div>
                    </Stack>
                    <PlatformSpace height={20}/>
                    <Typography variant="body3">
                        The Shredderz is a virtual band of metal legends and musicians that has emerged from the depths of
                        the metaverse to melt faces. With help from the Dark Lord, they aim to revolutionize metal. Fans can
                        become crowd-sourced managers and vote on song choices, merch designs, and more. Members will receive
                        exclusive content and priority access to future drops.<br/><br/>
                        The Shredderz Access Pass is an evolving token that grants Day 1 Shredderz fans priority access to
                        the Shredderz universe. Access Passes are dynamic NFTs that will transform to showcase new art and
                        simultaneously reward fans as The Shredderz reach new streaming milestones on Spotify. "
                    </Typography>
                    <PlatformSpace height={40}/>
                    <CalendarContainer component="div">
                        <Stack spacing={0}>
                            <Typography variant="h5">
                                Day One Sale
                            </Typography>
                            <Typography variant="body3" sx={{fontWeight: 600, marginTop: "10px"}}>
                                10,000 seeds
                            </Typography>
                            <Typography variant="body3" sx={{color: "rgb(112, 133, 131)"}}>
                                May 16 at 11:00 AM CDT
                            </Typography>
                            <PlatformSpace height={30}/>
                            <Stack direction="row" spacing={1}>
                                <CalendarButton variant="outlined">
                                    Add to calendar
                                </CalendarButton>
                                <ReminderButton variant="outlined">
                                    Set a reminder
                                </ReminderButton>
                            </Stack>
                        </Stack>
                    </CalendarContainer>
                </Grid2>
                <Grid2 item md={1}>
                    <div></div>
                </Grid2>
                <Grid2 item md={6}>
                    <ImageContainer>
                        <Image src={`${process.env.PUBLIC_URL}/images/drops/slide${thumbnailSelected + 1}.jpeg`}/>
                        <PlatformSpace height={10}/>
                        <Grid2 container spacing={2}>
                            {SLIDES.map((i, idx) => {
                                return (
                                    <Grid2 item md={3}>
                                        <Thumbnail selected={idx === thumbnailSelected} onClick={() => onSelectThumbnail(idx)}
                                                   src={`${process.env.PUBLIC_URL}/images/drops/slide${i}.jpeg`}/>
                                    </Grid2>
                                )
                            })}
                        </Grid2>
                    </ImageContainer>
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

const CalendarButton = styled(Button)(({ theme }) => ({
    border: "1px solid grey",
    borderRadius: 20,
    padding: "15px !important",
    backgroundImage: "none !important"
}));

const ReminderButton = styled(Button)(({ theme }) => ({
    border: "1px solid grey",
    borderRadius: 20,
    padding: "15px !important",
    backgroundImage: "none !important",
    backgroundColor: "#1976d2 !important",
    color: "white !important"
}));

const CalendarContainer = styled(Box)(({ theme }) => ({
    border: "1px solid grey",
    borderRadius: 20,
    padding: 25
}));

const ImageContainer = styled('div')(({ theme }) => ({
    width: "45vw",
    borderRadius: 20,
    paddingLeft: 30,
    marginLeft: "auto"
}));

const Image = styled('img')(({ theme }) => ({
    width: "100%",
    pointerEvents: "none",
    borderRadius: 20
}));

const Thumbnail = styled('img')(({ selected }) => ({
    width: "100%",
    borderRadius: 20,
    margin: 5,
    cursor: "pointer !important",
    ...(selected && {
        borderRadius: 18,
        border: "3px solid #1976d2"
    })
}));

const BrandLogo = styled('img')(({ theme }) => ({
    border: "1px solid white",
    borderRadius: 12
}));

const DropTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.1rem",
    letterSpacing: 0.7,
    color: "white",
}));

const DropSubTitle = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.1rem",
    letterSpacing: 0.7,
    color: "white",
    opacity: 0.8
}));

const VerifiedIcon = styled(Verified)(({ theme }) => ({
    color: "rgb(32, 129, 226) !important",
    marginLeft: 5,
}));