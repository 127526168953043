import * as React from 'react';
import {styled} from "@mui/styles";
import Grid from "@mui/material/Grid";

export default function CatalogItemAttributes(props) {

    return (
      <Grid container spacing={1}>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Height</AttributeTitle>
                      <AttributeValue>Tall</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Yield</AttributeTitle>
                      <AttributeValue>High</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Flowering</AttributeTitle>
                      <AttributeValue>Fast</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
            <div style={{display: "flex"}}>
                <Attribute>
                    <AttributeTitle>THC percent</AttributeTitle>
                    <AttributeValue>20% to 23%</AttributeValue>
                </Attribute>
            </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>CBD percent</AttributeTitle>
                      <AttributeValue>4% to 5%</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Chemotype</AttributeTitle>
                      <AttributeValue>THC Dominant</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Phenotype</AttributeTitle>
                      <AttributeValue>Hybrid</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Indoor yield</AttributeTitle>
                      <AttributeValue>400/g x m²</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Outdoor yield</AttributeTitle>
                      <AttributeValue>600/g x m²</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Climate</AttributeTitle>
                      <AttributeValue>Long summers</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Flavor</AttributeTitle>
                      <AttributeValue>Gas</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
          <Grid item md={4}>
              <div style={{display: "flex"}}>
                  <Attribute>
                      <AttributeTitle>Effects</AttributeTitle>
                      <AttributeValue>Happy, Chill</AttributeValue>
                  </Attribute>
              </div>
          </Grid>
      </Grid>
    )
}

const Attribute = styled('div')(({ theme }) => ({
    backgroundColor: "rgba(70,50,95,0.5)",
    border: "1px solid #1565c0",
    padding: 15,
    borderRadius: 4,
    width: "100%",
    height: "100%",
    textAlign: "center"
}));

const AttributeTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "0.7rem",
    color: "rgba(177,123,255,0.5)",
    textTransform: "uppercase"
}));

const AttributeValue = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.1rem",
    color: "rgba(255,255,255,0.85)",
}));