import React from "react";
import PlatformSpace from "../../components/platform/platformSpace";
import {styled} from "@mui/styles";
import {Verified} from "@mui/icons-material";
import {Stack} from "@mui/material";

export default function CheckoutHeader(props) {
    return (
        <React.Fragment>
            <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                <img height={175} src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}
                     style={{border: "1px solid white", borderRadius: 4, height: 35}}/>
                <BrandTitle>
                    Gas Giant
                </BrandTitle>
            </Stack>
            <PlatformSpace height={10}/>
        </React.Fragment>
    );
}

const VerifiedIcon = styled(Verified)(({ theme }) => ({
    color: "rgb(32, 129, 226) !important",
    marginLeft: 5
}));

const BrandTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));