import PublicLayout from "components/layout/publicLayout";
import React from "react";
import DropsHome from "./dropsHome";
import {DropsContextProvider} from "./dropsContext";

export default function DropsIndex(props) {
    return (
        <PublicLayout>
            <DropsContextProvider>
                <DropsHome/>
            </DropsContextProvider>
        </PublicLayout>
    );
}