import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {HomeContextProvider} from "./homeContext";
import HomeHome from "./homeHome";

export default function HomeIndex(props) {
    return (
        <PublicLayout zeroMargin fullWidth>
            <HomeContextProvider>
                <HomeHome/>
            </HomeContextProvider>
        </PublicLayout>
    );
}