import React from "react";

export default function PlatformCartSplitButtonWrapper(props) {
    const {relative, width, children} = props;

    return (
        <React.Fragment>
            {relative &&
                <div style={{position: "relative", width: "300px !important"}}>
                    {children}
                </div>
            }
            {!relative &&
                <React.Fragment>
                    {children}
                </React.Fragment>
            }
        </React.Fragment>
    );
}