import React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import HomeSectionBar from "../home/homeSectionBar";
import DropsGridTile from "./dropsGridTile";

export default function DropsCatalog(props) {

    return (
        <div>
            <Grid container rowSpacing={3} spacing={2}>
                {FLOWER.sort(() => 0.5 - Math.random()).map((flower) => {
                    return (
                        <Grid item>
                            <DropsGridTile title={flower.title} image={flower.image}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}

const FLOWER = [
    {title: "Wedding Cake", image: "flower/_DDD8101_weddingcakeLiveBud.jpg", content: <HomeSectionBar/>},
    {title: "Kush Mintz", image: "flower/_DDD8130_KushMintsLiveFlower_1.jpg", content: <HomeSectionBar/>},
    {title: "Pink Runtz", image: "flower/_DDD8179_pinkruntsLIVEflower.jpg", content: <HomeSectionBar/>},
    {title: "Chem dela Chem", image: "flower/_DDD8182_chemdelachemLIVEMacro.jpg", content: <HomeSectionBar/>},
    {title: "Wedding Crasher", image: "flower/_DDD8185_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "GGG", image: "flower/_DDD8192_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Erkle", image: "flower/_DDD8203_kushmintsLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Poison", image: "flower/_DDD8264_purplepoisonLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Poison Beans", image: "flower/_DDD8274_poisonbeansLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Swaziland Gold", image: "flower/_DDD8289_swazilandgoldLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Ice Cream Mints", image: "flower/_DDD8391_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Skywalker OG", image: "flower/_DDD8398_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Monster Kush", image: "flower/_DDD8455.jpg", content: <HomeSectionBar/>},
    {title: "Green Gelato", image: "flower/_DDD8500.jpg", content: <HomeSectionBar/>},
    {title: "Sweet Island Skunk", image: "flower/_DDD8533.jpg", content: <HomeSectionBar/>},
    {title: "Southern Gas", image: "flower/_DDD8543.jpg", content: <HomeSectionBar/>},
    {title: "Northern Lights", image: "flower/_DRW8561.jpg", content: <HomeSectionBar/>},
    {title: "White Widow", image: "flower/_DRW8616.jpg", content: <HomeSectionBar/>},
    {title: "Kush Cream Mints", image: "flower/_DRW8618.jpg", content: <HomeSectionBar/>}
]

//http://bideowego.com/css3-border-radius-transitions
//https://stackoverflow.com/questions/31693219/issue-while-using-transitions-opacity-change-overflow-hidden/31698580#31698580