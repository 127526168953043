import * as React from 'react';
import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {styled} from "@mui/styles";

export default function CatalogItemInfoBox(props) {
    const {alwaysExpanded, defaultExpanded, topRow, bottomRow, title, icon, content, ...allProps} = props;
    const [expanded, setExpanded] = useState(alwaysExpanded || defaultExpanded);
    const TitleIcon = icon;

    const onChange = (event, expanded) => {
        setExpanded(expanded);
    }

    const accordionProps = {
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: {enter: 0, exit: 0}
    }

    return (
        <Accordion
            {...allProps}
            onChange={onChange}
            expanded={alwaysExpanded}
            defaultExpanded={alwaysExpanded || defaultExpanded}
            TransitionProps={accordionProps}
            sx={{
                "&.Mui-expanded": {
                    margin: 0
                },
            }}>
            <AccordionSummary
                expandIcon={!alwaysExpanded ? <ExpandMore /> : null}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    borderRadius: !bottomRow ? (!topRow ? 0 : "12px 12px 0 0") : (expanded ? 0 : "0 0 12px 12px !important"),
                    border: "1px solid rgba(59,42,84, 0.80)",
                    borderTop: !topRow ? 0 : "1px solid rgba(59,42,84, 0.80)",
                    height: 65
                }}>
                <TitleIcon sx={{marginRight: 2}}/>{title}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    border: "1px solid rgba(59,42,84, 0.80)",
                    backgroundColor: "rgb(41, 31, 55) !important",
                    borderRadius: expanded && bottomRow ? "0 0 12px 12px" : "inherit",
                    borderTop: "none",
                    color: "white",
                    fontWeight: "400 !important",
                    fontSize: "1rem !important",
                }}>
                {content}
            </AccordionDetails>
        </Accordion>
    )
}

const InfoBoxSummary = styled(AccordionSummary)(({topRow, bottomRow, expanded}) => ({
    borderRadius: !bottomRow ? (!topRow ? "0 !important" : "12px 12px 0 0 !important") : (expanded ? "0 !important" : "0 0 12px 12px !important"),
    border: "1px solid rgba(59,42,84, 0.80) !important",
    borderTop: !topRow ? "0" : "1px solid rgba(59,42,84, 0.80) !important",
    height: 65
}));