import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CompareContextProvider} from "./compareContext";
import CompareHome from "./compareHome";

export default function CompareIndex(props) {
    return (
        <PublicLayout>
            <CompareContextProvider>
                <CompareHome/>
            </CompareContextProvider>
        </PublicLayout>
    );
}