import React, {useState} from "react";

export const CartContext = React.createContext({});
export default CartContext;

export const CartContextProvider = ({children}) => {
    const [wizardStep, setWizardStep] = useState(1);

    return (
        <CartContext.Provider
            value={{
                wizardStep,
                setWizardStep
            }}
        >
            <>{children}</>
        </CartContext.Provider>
    );
}