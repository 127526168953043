import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled, withStyles} from "@mui/styles";
import {Stack} from "@mui/material";
import {useHistory} from "react-router-dom";
import PlatformSpace from "../../components/platform/platformSpace";

function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function HomeChart(props) {
    const {data, startIdx} = props;
    const history = useHistory();

    return (
        <TableContainer sx={{minWidth: "40vw"}}>
            <Table sx={{minWidth: "100%"}}>
                <TableHead sx={{
                    "& .MuiTableCell-head": {
                        color: "rgb(112, 122, 131)",
                        fontWeight: 200,
                        fontSize: "0.9rem",
                        //textTransform: "uppercase",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.2)"
                    },
                }}>
                    <TableRow>
                        <TableCell sx={{maxWidth: 5}}>Rank</TableCell>
                        <TableCell>Breeder</TableCell>
                        <TableCell align="right">Genetics</TableCell>
                        <TableCell align="right">Awards</TableCell>
                    </TableRow>
                </TableHead>
                <PlatformSpace height="5"/>
                <TableBody>
                    {data.map((row, idx) => (
                        <CustomTableRow key={row.name} sx={{
                            border: 0,
                            cursor: "pointer",
                            "&:hover": {
                                // textDecorationLine: "underline",
                                // textDecorationThickness: "10px !important",
                                backgroundColor: "rgba(59,42,84, 0.80) !important",
                            }
                        }}>
                            <StyledTableCell>
                                <div style={{
                                    maxWidth: 5,
                                    fontSize: "1.2rem",
                                    color: "rgba(255, 255, 255, 0.5)"
                                }}>
                                    {((startIdx || 0) + idx) + 1}
                                </div>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Stack direction="row"
                                       spacing={1}
                                       justifyContent="flex-start"
                                       alignItems="center">
                                    <div>
                                        <img
                                            style={{borderRadius: 8, minHeight: 68, maxHeight: 68, paddingRight: 20}}
                                            src={`${process.env.PUBLIC_URL}/images/${row.image}`}
                                            srcSet={`${process.env.PUBLIC_URL}/images/${row.image}`}
                                            alt={row.title}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div onClick={() => history.push("/breeder/gasgiant")}>{row.title}</div>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="right">{23}</StyledTableCell>
                            <StyledTableCell align="right">{39}</StyledTableCell>
                        </CustomTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const CustomTableRow = styled(TableRow)(({  }) => ({
    '&.MuiTableRow-root td:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    '&.MuiTableRow-root td:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
}));

const StyledTableCell = withStyles({
    root: {
        color: "white !important",
        fontWeight: "600 !important",
        fontSize: "1rem !important",
        borderBottom: "none !important"
    },
})(TableCell);