import React, {useState} from "react";

export const CheckoutPaymentFormContext = React.createContext({});
export default CheckoutPaymentFormContext;

export const CartAccordionContextProvider = ({defaultOptionType, children}) => {
    const [optionType, setOptionType] = useState(defaultOptionType);

    return (
        <CheckoutPaymentFormContext.Provider
            value={{
                optionType,
                setOptionType
            }}
        >
            <>{children}</>
        </CheckoutPaymentFormContext.Provider>
    );
}