import * as React from 'react';
import {useRef} from 'react';
import PlatformForm from "../../../components/platform/platformForm";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import SubscribeSummary from "./subscribeSummary";
import SubscribePayment from "./subscribePayment";
import useLayoutOptions from "../../../components/hook/useLayoutOptions";

export default function SubscribeHome(props) {
    const formRef = useRef();

    useLayoutOptions({
        headerComponent: null,
        footerComponent: null
    });

    return (
        <PlatformForm ref={formRef}>
            <PaneContainer direction="row">
                <LeftPane>
                    <SubscribePayment/>
                </LeftPane>
                <RightPane>
                    <SubscribeSummary/>
                </RightPane>
            </PaneContainer>
        </PlatformForm>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    backgroundColor: "rgba(41, 31, 55, 0.8)",
    height: "100vw"
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    backgroundColor: "rgba(41, 31, 55, 1)",
    height: "100vh"
}));