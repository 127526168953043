import * as React from 'react';
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {Divider, Stack} from "@mui/material";

export default function CheckoutFooter(props) {
    return (
        <div style={{width: "100%"}}>
            <CartDivider/>
            <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                <Typography variant="body5">
                    Refund policy
                </Typography>
                <Typography variant="body5">
                    Shipping policy
                </Typography>
                <Typography variant="body5">
                    Privacy policy
                </Typography>
                <Typography variant="body5">
                    Terms of service
                </Typography>
            </Stack>
        </div>
    )
}

const CartDivider = styled(Divider)(({  }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));