import React, {useState} from "react";

export const SubscribeContext = React.createContext({});
export default SubscribeContext;

export const SubscribeContextProvider = ({children}) => {
    const [wizardStep, setWizardStep] = useState(1);

    return (
        <SubscribeContext.Provider
            value={{
                wizardStep,
                setWizardStep
            }}
        >
            <>{children}</>
        </SubscribeContext.Provider>
    );
}