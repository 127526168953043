import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CartContextProvider} from "./checkoutContext";
import CheckoutHome from "./checkoutHome";

export default function CheckoutIndex(props) {
    return (
        <PublicLayout>
            <CartContextProvider>
                <CheckoutHome/>
            </CartContextProvider>
        </PublicLayout>
    );
}