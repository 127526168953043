import * as React from 'react';
import {useRef} from 'react';
import {styled} from "@mui/styles";
import {Stack} from "@mui/material";
import BreederSubscription from "./breederSubscription";
import PlatformSpace from "../../components/platform/platformSpace";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function BreederSubscriptions(props) {
    const formRef = useRef();
    const history = useHistory();

    const onCompareAll = () => {
        history.push("/breeder/gasgiant/subscribe/compare")
    }

    return (
        <React.Fragment>
            {/*<PlatformTabs scrollTo={650} fontSize="1.4rem" centered tabs={[*/}
            {/*    {label: "Join the community", content: <div/>},*/}
            {/*]}/>*/}
            <PlatformSpace height={30}/>
            <Stack justifyContent="center" alignItems="flex-start">
                <CardStack justifyContent="center" direction="row" alignItems="flex-start" spacing={5}>
                    <BreederSubscription
                        title="Aspiring Grower"
                        price="$5.99"
                        benefits={[
                            {
                                title: "Access to exclusive drops",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "5% discount on all products",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "Ask the grower",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            }
                        ]}
                    />
                    <BreederSubscription
                        title="Seasoned Pro"
                        price="$9.99"
                        benefits={[
                            {
                                title: "Access to exclusive drops",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "5% discount on all products",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "Ask the grower",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            }
                        ]}
                    />
                    <BreederSubscription
                        title="Master Grow"
                        price="$19.99"
                        benefits={[
                            {
                                title: "Access to exclusive drops",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "5% discount on all products",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            },
                            {
                                title: "Ask the grower",
                                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                            }
                        ]}
                    />
                </CardStack>
                <PlatformSpace height={30}/>
                <ViewBreederButton variant="outlined" onClick={onCompareAll}>
                    Compare all plans
                </ViewBreederButton>
            </Stack>
        </React.Fragment>
    )
}

const ViewBreederButton = styled(Button)(({ theme }) => ({
    width: "200px !important",
    border: "1px solid grey",
    borderRadius: 20,
    padding: "15px !important",
    backgroundImage: "none !important",
    margin: "auto !important"
}));

const CardStack = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
}));
