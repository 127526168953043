import React from "react";

export const BreederContext = React.createContext({});
export default BreederContext;

export const BreederContextProvider = ({children}) => {

    return (
        <BreederContext.Provider
            value={{
            }}
        >
            <>{children}</>
        </BreederContext.Provider>
    );
}