import * as React from 'react';
import {useContext, useRef} from 'react';
import PlatformForm from "../../components/platform/platformForm";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import CheckoutSummary from "./checkoutSummary";
import CheckoutStep2Shipping from "./checkoutStep2Shipping";
import CheckoutStep3Payment from "./checkoutStep3Payment";
import CheckoutContext from "./checkoutContext";
import CheckoutStep1Information from "./checkoutStep1Information";
import useLayoutOptions from "../../components/hook/useLayoutOptions";

export default function CheckoutHome(props) {
    const formRef = useRef();
    const {wizardStep} = useContext(CheckoutContext);

    useLayoutOptions({
        headerComponent: null,
        footerComponent: null
    });

    return (
        <PlatformForm ref={formRef}>
            <PaneContainer direction="row">
                <LeftPane>
                    {wizardStep === 1 && <CheckoutStep1Information/>}
                    {wizardStep === 2 && <CheckoutStep2Shipping/>}
                    {wizardStep === 3 && <CheckoutStep3Payment/>}
                </LeftPane>
                <RightPane>
                    <CheckoutSummary/>
                </RightPane>
            </PaneContainer>
        </PlatformForm>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    backgroundColor: "rgba(41, 31, 55, 0.8)",
    height: "100vw"
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    backgroundColor: "rgba(41, 31, 55, 1)",
    height: "100vh"
}));