import React from "react";
import {Stack} from "@mui/material";
import HomeFeatureTab from "./homeFeatureTab";
import {useHistory} from "react-router-dom";

export default function HomeFeatureTabs(props) {
    const history = useHistory();

    const onDrops = () => {
        history.push("/drops");
    }

    return (
        <Stack direction="row" spacing={1} sx={{width: "100vw", marginLeft: "auto", paddingLeft: "30px !important"}}>
            <HomeFeatureTab text="All" selected/>
            <HomeFeatureTab text="Seeds"/>
            <HomeFeatureTab text="Clones"/>
            <HomeFeatureTab text="Breeders"/>
            <HomeFeatureTab text="Auctions"/>
            <HomeFeatureTab text="Drops" onClick={onDrops}/>
        </Stack>
    );
}