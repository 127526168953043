import axios from "axios";
import qs from "qs";

const API_URL = `//${process.env.REACT_APP_HOST || "localhost:5001"}`;

const init = () => {
    return axios.create({
        baseURL: `//${process.env.REACT_APP_HOST || "localhost:5001"}`,
        timeout: 31000,
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Store-ID': "64a44bcc94f737636525878a",//`${retrieveString("storeId")}`,
            'Authorization': null//`Bearer ${retrieveString("token")}`
        }
    });
};

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
//https://axios-http.com/docs/handling_errors
//https://github.com/axios/axios

export default {


    POST: function (config, successCallback, errorCallback) {
        init().post(config.url, config.params)
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    PUT: function (config, successCallback, errorCallback) {
        init().put(config.url, config.params)
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    GET: function (config, successCallback, errorCallback) {
        init().get(config.url, {params: config.params, paramsSerializer: params => {
                return qs.stringify(params)
            }})
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    DELETE: function (config, successCallback, errorCallback) {
        init().delete(config.url, {params: config.params, paramsSerializer: params => {
                return qs.stringify(params)
            }})
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },


    PATCH: function (config, successCallback, errorCallback) {
        init().patch(config.url, {data: config.params})
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },


    POST_FORM: function (config, successCallback, errorCallback) {
        fetch(API_URL + config.url, {
            method: "POST",
            headers: {
                'Authorization':`Bearer ${localStorage.getItem("token")}`
            },
            body: config.formData
        }).then(response => {
            if (typeof successCallback === 'function') {
                successCallback(response.json());
            } else {
                console.log('callback: ', response)
            }
        }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    PUT_FORM: function (config, successCallback, errorCallback) {
        fetch(API_URL + config.url, {
            method: "PUT",
            headers: {
                'Authorization':`Bearer ${localStorage.getItem("token")}`
            },
            body: config.formData
        }).then(response => {
            if (typeof successCallback === 'function') {
                successCallback(response);
            } else {
                console.log('callback: ', response)
            }
        }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },
}
/*
import http from "../http-common";
class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  getFiles() {
    return http.get("/files");
  }
}
export default new UploadFilesService();
 */