import * as React from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "components/platform/platformSpace";
import PlatformTextField from "components/platform/platformTextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";

export default function CatalogCheckboxGroupFilter(props) {
    const {options} = props;

    return (
        <React.Fragment>
            <PlatformSpace height={10}/>
            <PlatformTextField
                startAdornment={<SearchIcon/>}
                placeholder="Search"
            />
            <Stack sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                <PlatformSpace height={10}/>
                {options.map((option) => {
                    return (
                        <FormControlLabel
                            label={option}
                            control={<Checkbox checked={false} />}
                        />
                    )
                })}
            </Stack>
        </React.Fragment>
    )
}

const FilterText = styled('span')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white",
    textTransform: "lowercase",
    lineHeight: "3rem",
    margin: "0 8px 0 8px"
}));