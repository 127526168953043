import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import Checkbox from "@mui/material/Checkbox";
import PlatformLabel from "./platformLabel";
import PlatformFormContext from "./platformFormContext";
import {Stack} from "@mui/material";

export default function PlatformCheckbox(props) {
  const classes = useStyles();
  const { label, value, checked, dense, reversed, containerStyle, labelStyle, inverted, width, name,
    clickHandler, defaultValue, grouped, disabled, ...allProps } = props;
  const [toggled, setToggled] = useState(checked === undefined ? false : checked);
  const context = useContext(PlatformFormContext);

  useEffect(() => {
    if (!grouped && context && context.addField) {
      context.addField(name, {
        name: name,
        setDefaultValue: setDefaultValue,
        validate: onValidate,
        setValue: setFormValue,
        changeHandler: changeHandler
      });
      if (defaultValue) {
        setDefaultValue(defaultValue);
      }
    }
  }, []);

  const setFormValue = (value) => {
    setToggled(Boolean(value));
  }

  useEffect(() => {
    if (disabled && !defaultValue) {
      setToggled(false);
      context.removeField(name);
    }
  }, [disabled])

  const setDefaultValue = (defaultValue) => {
    if (!grouped && context != null) {
      //context.form.current.setValue(name, defaultValue);
    }
    setToggled(Boolean(defaultValue) || "");
  }

  const onValidate = () => {
    return true;
  }

  const changeHandler = (event) => {
    setToggled(event.target.checked);
    innerChangeHandler(event, event.target.value);
  };

  const innerChangeHandler = (event, value) => {
    if (!grouped && context != null) {
      context.form.current.onChange(name, value);
    }
  }

  const onClick = (event) => {
    const newToggle = !toggled;

    setToggled(newToggle);

    if (typeof clickHandler === "function") {
      clickHandler(newToggle, value, event);
    }

    if (!grouped && context != null) {
      context.form.current.onChange(name, newToggle);
    }
  }

  let { col1, col2 } = 0;
  if (label && !dense) {
    col1 = 12;
    col2 = 12;
  } else if (!label && !dense) {
    col1 = null;
    col2 = 12;
  }
  if (label && dense) {
    col1 = 1;
    col2 = 11;
  }

  const getCheckbox = () => {
    if (!reversed) {
      return (
          <Grid container spacing={0}>
            {label &&
                <Grid item xs={col1} style={{ marginTop: 4 }}>
                  <PlatformLabel onClick={onClick} inverted={inverted}>{label}</PlatformLabel>
                </Grid>
            }
            <Grid item xs={col2}>
              <StyledCheckbox
                  {...allProps}
                  disabled={Boolean(disabled)}
                  className={classes.checkbox}
                  color="default"
                  onClick={onClick}
                  checked={Boolean(toggled)}
              />
            </Grid>
          </Grid>
      );
    } else {
      return (
          <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={0}>
            <StyledCheckbox
                {...allProps}
                disabled={Boolean(disabled)}
                className={classes.checkbox}
                color="default"
                onClick={onClick}
                checked={Boolean(toggled)}
            />
            <PlatformLabel onClick={onClick} inverted={inverted}>{label}</PlatformLabel>
          </Stack>
      )
    }
  };

  return getCheckbox();
};

function StyledCheckbox(props) {
  const classes = useStyles();
  const {checked} = props;

  return (
      <Checkbox
          disableRipple
          checked={Boolean(checked)}
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
          icon={<span className={classes.icon}/>}
          inputProps={{ "aria-label": "decorative checkbox" }}
          {...props}
      />
  );
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    "&$disabled": {
      color: "black"
    },
    fontSize: 16
  },
  disabled: {},
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  checkbox: {},
  underline: {
    "&:before": {
      borderBottom: "2px solid #e0e0e0"
    },
    "&:after": {
      borderBottom: "2px solid #5390cc"
    }
  },
  root2: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "rgba(69,70,145,255)",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: "\"\""
    },
    "input:hover ~ &": {
      backgroundColor: "rgba(69,70,145,255)"
    }
  }
}));