import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import PlatformForm from "components/platform/platformForm";
import {Stack} from "@mui/material";
import {
    AssignmentReturned,
    AutoAwesome,
    CalendarViewMonth,
    FaceRetouchingNatural,
    FavoriteBorder,
    Label,
    Schedule,
    Schema,
    Sell,
    Subject,
    Verified,
    VerticalSplit
} from "@mui/icons-material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../../components/platform/platformSpace";
import Button from "@mui/material/Button";
import PlatformTextField from "../../../../components/platform/platformTextField";
import CatalogItemInfoBox from "./catalogItemInfoBox";
import CatalogItemAttributes from "./catalogItemAttributes";
import PlatformCartSplitButton from "../../../../components/platform/platformCartSplitButton";
import CatalogItemLineage from "./catalogItemLineage";
import useWindowSize from "../../../../components/hook/useWindowSize";
import CatalogItemTerpenes from "./catalogItemTerpenes";
import CatalogItemTableBox from "./catalogItemTableBox";
import HomeSectionBar from "../../../home/homeSectionBar";
import Grid from "@mui/material/Grid";
import PlatformItemGridTile from "../../../../components/platform/platformItemGridTile";

export default function CatalogItemHome(props) {
    const formRef = useRef();
    const containerRef = useRef(null);
    const [rightColumnWidth, setRightColumnWidth] = useState(0);

    useWindowSize(() => updateHeight());

    useEffect(() => {
        setTimeout(() => {
            updateHeight();
        }, 25)
    });

    const updateHeight = () => {
        setRightColumnWidth(containerRef.current.getBoundingClientRect().height);
    }

    // https://opensea.io/assets/ethereum/0x0394b9121bd481260258e6eb93a0ba47580b86ef/2487

    return (
        <PlatformForm ref={formRef}>
            <div style={{width: "95vw", margin: "auto", marginTop: 15}}>
                <Stack direction="row" spacing={3}>
                    <div style={{maxWidth: "40vw"}}>
                        <div style={{border: "1px solid rgba(59,42,84, 0.80)", borderRadius: 12, width: "100%", overflow: "hidden", padding: "0 5px 5px 5px"}}>
                            <Stack sx={{height: 45}} spacing={1} direction="row" alignItems="center" justifyContent="flex-end">
                                <span style={{fontWeight: 400, fontSize: "0.8rem", color: "white"}}>5</span><FavoriteBorder fontSize="small"/>
                                <span style={{marginRight: 1}}/>
                            </Stack>
                            <ImageContainer>
                                <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} width="100%" />
                            </ImageContainer>
                        </div>
                        <PlatformSpace height={20}/>
                        <CatalogItemInfoBox
                            alwaysExpanded
                            topRow
                            icon={Subject}
                            title="Description"
                            content={
                                <div style={{padding: "15px 0 10px 10px"}}>
                                    Triple G Automatic outshines the vast majority of autoflowers on the market.
                                    Her high levels of THC square up favourably against many photoperiod strains to boot,
                                    and her complex and delicious terpene profile never fails to impress. On top of
                                    these key traits, she delivers a weighty yield in a matter of weeks. If you're
                                    searching for potent flowers and a brief growing cycle, look no further.
                                </div>
                            }
                        />
                        <CatalogItemInfoBox
                            bottomRow
                            defaultExpanded
                            icon={VerticalSplit}
                            title="About Gas Giant"
                            content={
                                <div style={{padding: "15px 0 10px 10px"}}>
                                    By <strong>BurnGhostGames</strong><br/>
                                    The Burn Ghost Genesis Pass will entitle a lucky 777 holders to a growing number
                                    of special privileges within the Burn Ghost platform and community.
                                </div>
                            }
                        />
                    </div>
                    <div style={{width: "100%"}} ref={containerRef}>
                        <PlatformSpace height={15}/>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <BrandName>Gas Giant</BrandName>
                            <Verified fontSize="small" sx={{color: "#1565c0"}}/>
                        </Stack>
                        <PlatformSpace height={40}/>
                        <ProductName>Triple G Automatic</ProductName>
                        <PlatformSpace height={10}/>
                        <Aka>Triple G x Big Skunk Auto</Aka>
                        <PlatformSpace height={40}/>
                        <div style={{border: "1px solid rgba(59,42,84, 0.80)", borderRadius: 12, overflow: "hidden"}}>
                            <div style={{minHeight: 60, borderBottom: "1px solid rgba(59,42,84, 0.80)", padding: 20}}>
                                <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                                    <Schedule/>
                                    <div style={{fontSize: "1.2rem", color: "white", fontWeight: 400}}>Sale ends April 19, 2023 at 7:50 PM</div>
                                </Stack>
                            </div>
                            <div style={{height: "100%", padding: 20}}>
                                <Stack spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                    <FormLabel>Current price</FormLabel>
                                    <div style={{fontSize: "2rem", color: "white", fontWeight: 600}}>$100.00</div>
                                    <PlatformSpace height={10}/>
                                    <FormLabel>Number of seeds</FormLabel>
                                    <Stack spacing={2} direction="row" alignItems="flex-start" justifyContent="flex-start">
                                        <QuantityButton selected variant="outlined">3x</QuantityButton>
                                        <QuantityButton variant="outlined">6x</QuantityButton>
                                        <QuantityButton variant="outlined">9x</QuantityButton>
                                        <QuantityButton variant="outlined">12x</QuantityButton>
                                    </Stack>
                                    <PlatformTextField
                                        centered
                                        label="Quantity"
                                        sx={{width: 80}}
                                        defaultValue={1}
                                    />
                                    <PlatformSpace height={10}/>
                                    <div style={{position: "relative", width: 550, height: 65}}>
                                        <PlatformCartSplitButton height={65} width={270}/>
                                        <MakeOfferButton startIcon={<Sell/>} variant="contained">Make offer</MakeOfferButton>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                        <PlatformSpace height={40}/>
                        <CatalogItemInfoBox
                            defaultExpanded
                            topRow
                            icon={Label}
                            title="Attributes"
                            content={<CatalogItemAttributes/>}
                        />
                        <CatalogItemInfoBox
                            icon={AssignmentReturned}
                            title="Downloads"
                            content="Content coming soon"
                        />
                        <CatalogItemInfoBox
                            icon={FaceRetouchingNatural}
                            title="Effects"
                            content="Content coming soon"
                        />
                        <CatalogItemInfoBox
                            icon={AutoAwesome}
                            title="Terpenes"
                            content={<CatalogItemTerpenes width={rightColumnWidth}/>}
                        />
                        <CatalogItemInfoBox
                            bottomRow
                            icon={Schema}
                            title="Lineage"
                            content={<CatalogItemLineage width={rightColumnWidth}/>}
                        />
                        {/* sale */}
                        {/* cart controls */}
                    </div>
                </Stack>
                <PlatformSpace height={20}/>
                <CatalogItemTableBox
                    icon={CalendarViewMonth}
                    defaultExpanded
                    title="More From This Breeder"
                    content={
                        <Grid
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            container rowSpacing={3} spacing={4}>
                            {FLOWER.sort(() => 0.5 - Math.random()).map((flower) => {
                                return (
                                    <Grid item>
                                        <PlatformItemGridTile
                                            title={flower.title}
                                            image={flower.image}
                                            width="14vw"
                                            mode="commerce"
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    actions={
                        <ViewBreederButton variant="outlined">
                            View breeder
                        </ViewBreederButton>
                    }
                />
            </div>
        </PlatformForm>
    )
}

const ViewBreederButton = styled(Button)(({ theme }) => ({
    width: "200px !important",
    border: "1px solid grey",
    borderRadius: 20,
    padding: "15px !important",
    backgroundImage: "none !important",
    margin: "auto !important"
}));

const ImageContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    borderRadius: "12px 12px 0 0",
    transition: ".3s ease-in-out",
    transform: !hover ? "scale(1)" : "scale(1.1)"
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: "35vw",
    width: "100%",
    borderRadius: "0 0 12px 12px",
    pointerEvents: "none"
}));

const FormLabel = styled('div')(({ theme }) => ({
    fontSize: "1rem",
    color: "rgb(112, 122, 131)",
    fontWeight: 400
}));

const QuantityButton = styled(Button)(({ selected }) => ({
    height: 65,
    backgroundImage: "none !important",
    color: "white !important",
    ...(selected && {
        backgroundColor: "rgb(41, 31, 55) !important",
    }),
}));

const MakeOfferButton = styled(Button)(({ theme }) => ({
    position: "absolute !important",
    right: "0 !important",
    height: 65,
    width: "270px !important",
    backgroundImage: "none !important",
}));

const BrandName = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#1565c0"
}));

const ProductName = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));

const Aka = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.2rem",
    color: "rgba(255, 255, 255, 0.7)",
}));


const FLOWER = [
    {title: "Wedding Cake", image: "flower/_DDD8101_weddingcakeLiveBud.jpg", content: <HomeSectionBar/>},
    {title: "Kush Mintz", image: "flower/_DDD8130_KushMintsLiveFlower_1.jpg", content: <HomeSectionBar/>},
    {title: "Pink Runtz", image: "flower/_DDD8179_pinkruntsLIVEflower.jpg", content: <HomeSectionBar/>},
    {title: "Chem dela Chem", image: "flower/_DDD8182_chemdelachemLIVEMacro.jpg", content: <HomeSectionBar/>},
    {title: "Wedding Crasher", image: "flower/_DDD8185_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "GGG", image: "flower/_DDD8192_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
]