import * as React from 'react';
import {useContext, useEffect, useRef} from 'react';
import PlatformForm from "../../components/platform/platformForm";
import PlatformNetflixSlider from "../../components/platform/platformNetflixSlider";
import PlatformSpace from "../../components/platform/platformSpace";
import HomeTopBreedersChart from "./homeTopBreedersChart";
import HomeSectionBar from "./homeSectionBar";
import HomeFeatureTabs from "./homeFeatureTabs";
import AppContext from "../../appContext";
import HomeBackgroundVideo from "./homeBackgroundVideo";
import PublicFooter from "../../components/layout/publicFooter";
import PlatformSliderButtons from "../../components/platform/platformSliderButtons";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";

export default function HomeHome(props) {
    const formRef = useRef();
    const appContext = useContext(AppContext);

    useEffect(() =>{
        appContext.onLayoutOptions({
            backgroundComponent: HomeBackgroundVideo,
            footerComponent: PublicFooter,
            blur: "2px",
            topMargin: 0
        });
        return () => appContext.onLayoutReset();
    }, [])

    return (
        <PlatformForm ref={formRef}>
            <PlatformSpace height={100}/>
            <HomeFeatureTabs/>
            <PlatformSpace height={40}/>
            <PlatformNetflixSlider
                itemSize="20vw"
                itemStyle="feature"
                slides={BREEDERS}
            />
            <PlatformSpace height={40}/>
            <TabContainer justifyContent="space-between" direction="row" alignItems="flex-start" sx={{width: "98vw"}}>
                <PlatformSliderButtons tabsWidth={250} tabWidth={125} tabs={[
                    {label: "Trending"},
                    {label: "Top"},
                ]}/>
                <PlatformSliderButtons tabsWidth={540} tabWidth={30} tabs={[
                    {label: "1h"},
                    {label: "6h"},
                    {label: "24h"},
                    {label: "7d"},
                    {label: "30d"},
                    {label: "All"},
                ]}/>
            </TabContainer>
            <PlatformSpace height={20}/>
            <TabContainer justifyContent="space-between" direction="row" alignItems="flex-start" sx={{width: "98vw"}}>
                <HomeTopBreedersChart/>
            </TabContainer>
            <PlatformSpace height={40}/>
            <PlatformNetflixSlider
                title="Landrace Legends"
                itemSize={225}
                itemStyle="section"
                perView={6}
                slides={FLOWER.sort(() => 0.5 - Math.random()).slice(0, 7)}
            />
            <PlatformSpace height={60}/>
            <PlatformNetflixSlider
                title="Gassy Giants"
                itemSize={225}
                itemStyle="section"
                perView={6}
                slides={FLOWER.sort(() => 0.5 - Math.random()).slice(8, 14)}
            />
            <PlatformSpace height={60}/>
            <PlatformNetflixSlider
                title="Award Winning Summer Strains"
                itemSize={225}
                itemStyle="section"
                perView={6}
                slides={FLOWER.sort(() => 0.5 - Math.random()).slice(5, 14)}
            />
        </PlatformForm>
    )
}

const TabContainer = styled(Stack)(({ width }) => ({
    paddingLeft: 30
}));

const BREEDERS = [
    {title: "ATX Genetics", image: "slides/slide1.png"},
    {title: "Beaman's Own", image: "slides/slide2.png"},
    {title: "Zain Alchemy", image: "slides/slide3.png"},
    {title: "Grey Area Strains", image: "slides/slide4.png"},
    {title: "Full Moon Genetics", image: "breeders/Full-Moon-Genetics-1.png"},
    {title: "Hawaiian Heirloom", image: "breeders/Hawaiian-Heirloom-Logo-300x300-1-2.jpg"},
    {title: "Alchemy Genetics", image: "breeders/alchemy-genetics-242x242.png"},
    {title: "Andromeda Strains", image: "breeders/andromeda-strains.jpg"},
    {title: "Annunaki Genetics", image: "breeders/annunaki-genetics.jpg"},
    {title: "Bat Country Farms", image: "breeders/bat-country-farms.png"},
    {title: "Big Als Exotics", image: "breeders/big-als-exotics-242x242.jpeg"},
    {title: "Blasted Genetics", image: "breeders/blasted-genetics.jpg"},
    {title: "Blockhead Buds Genetics", image: "breeders/blockhead-buds-genetics-242x242.png"},
    {title: "Boston Root Seed Co", image: "breeders/boston-roots-seed-co.jpg"},
    {title: "Compound Genetics", image: "breeders/compound-genetics-logo-1.jpg"},
    {title: "Crane City Cannabis", image: "breeders/crane-city-cannabis-242x242.jpg"},
    {title: "Dab Father Farms", image: "breeders/dab-father-farms-242x242.jpg"},
    {title: "Dank Genetics", image: "breeders/dank-genetics.png"},
    {title: "DNA Heritage", image: "breeders/dna-heritage.jpg"},
    {title: "Dojo Seed Co", image: "breeders/dojo-seed-co.jpg"},
    {title: "Double XX", image: "breeders/double-xx-242x242.jpg"},
    {title: "Exotic Genetix", image: "breeders/exoticgentix-2-1.jpg"},
    {title: "Family Trees", image: "breeders/family-trees-242x242.jpg"},
    {title: "Hidden Group Genetics", image: "breeders/hidden-group-genetics-242x242.jpg"},
    {title: "Honey Sticks Genetics", image: "breeders/honey-sticks-genetics.jpg"},
    {title: "In House Genetics", image: "breeders/in-house-genetics-1-2.jpg"},
    {title: "Lost River", image: "breeders/lost-river-logo.jpg"},
    {title: "Myers Creek Cannabis", image: "breeders/myers-creek-cannabis-242x242.png"},
    {title: "Neptune Seed Bank", image: "breeders/neptune-seed-bank.jpg"},
    {title: "Nine Fold Genetics", image: "breeders/nine-fold-genetics.jpg"}
]

const FLOWER = [
    {title: "Wedding Cake", image: "flower/_DDD8101_weddingcakeLiveBud.jpg", content: <HomeSectionBar/>},
    {title: "Kush Mintz", image: "flower/_DDD8130_KushMintsLiveFlower_1.jpg", content: <HomeSectionBar/>},
    {title: "Pink Runtz", image: "flower/_DDD8179_pinkruntsLIVEflower.jpg", content: <HomeSectionBar/>},
    {title: "Chem dela Chem", image: "flower/_DDD8182_chemdelachemLIVEMacro.jpg", content: <HomeSectionBar/>},
    {title: "Wedding Crasher", image: "flower/_DDD8185_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "GGG", image: "flower/_DDD8192_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Erkle", image: "flower/_DDD8203_kushmintsLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Poison", image: "flower/_DDD8264_purplepoisonLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Poison Beans", image: "flower/_DDD8274_poisonbeansLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Swaziland Gold", image: "flower/_DDD8289_swazilandgoldLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Ice Cream Mints", image: "flower/_DDD8391_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Skywalker OG", image: "flower/_DDD8398_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Monster Kush", image: "flower/_DDD8455.jpg", content: <HomeSectionBar/>},
    {title: "Green Gelato", image: "flower/_DDD8500.jpg", content: <HomeSectionBar/>},
    {title: "Sweet Island Skunk", image: "flower/_DDD8533.jpg", content: <HomeSectionBar/>},
    {title: "Southern Gas", image: "flower/_DDD8543.jpg", content: <HomeSectionBar/>},
    {title: "Northern Lights", image: "flower/_DRW8561.jpg", content: <HomeSectionBar/>},
    {title: "White Widow", image: "flower/_DRW8616.jpg", content: <HomeSectionBar/>},
    {title: "Kush Cream Mints", image: "flower/_DRW8618.jpg", content: <HomeSectionBar/>}
]