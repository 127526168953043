import * as React from 'react';
import {Stack} from "@mui/material";
import HomeChart from "./homeChart";

export default function HomeTopBreedersChart(props) {
    return (
        <Stack direction="row"
               justifyContent="space-between"
               spacing={10}
               sx={{width: "98vw"}}
               alignItems="center">
            <HomeChart startIdx={0} data={BREEDERS.sort(() => 0.5 - Math.random()).slice(0, 5)}/>
            <HomeChart startIdx={5} data={BREEDERS.sort(() => 0.5 - Math.random()).slice(6, 11)}/>
        </Stack>
    )
}

const BREEDERS = [
    {title: "Full Moon Genetics", image: "breeders/Full-Moon-Genetics-1.png"},
    {title: "Hawaiian Heirloom", image: "breeders/Hawaiian-Heirloom-Logo-300x300-1-2.jpg"},
    {title: "Alchemy Genetics", image: "breeders/alchemy-genetics-242x242.png"},
    {title: "Andromeda Strains", image: "breeders/andromeda-strains.jpg"},
    {title: "Annunaki Genetics", image: "breeders/annunaki-genetics.jpg"},
    {title: "Bat Country Farms", image: "breeders/bat-country-farms.png"},
    {title: "Big Als Exotics", image: "breeders/big-als-exotics-242x242.jpeg"},
    {title: "Blasted Genetics", image: "breeders/blasted-genetics.jpg"},
    {title: "Blockhead Buds Genetics", image: "breeders/blockhead-buds-genetics-242x242.png"},
    {title: "Boston Root Seed Co", image: "breeders/boston-roots-seed-co.jpg"},
    {title: "Compound Genetics", image: "breeders/compound-genetics-logo-1.jpg"},
    {title: "Crane City Cannabis", image: "breeders/crane-city-cannabis-242x242.jpg"},
    {title: "Dab Father Farms", image: "breeders/dab-father-farms-242x242.jpg"},
    {title: "Dank Genetics", image: "breeders/dank-genetics.png"},
    {title: "DNA Heritage", image: "breeders/dna-heritage.jpg"},
    {title: "Dojo Seed Co", image: "breeders/dojo-seed-co.jpg"},
    {title: "Double XX", image: "breeders/double-xx-242x242.jpg"},
    {title: "Exotic Genetix", image: "breeders/exoticgentix-2-1.jpg"},
    {title: "Family Trees", image: "breeders/family-trees-242x242.jpg"},
    {title: "Hidden Group Genetics", image: "breeders/hidden-group-genetics-242x242.jpg"},
    {title: "Honey Sticks Genetics", image: "breeders/honey-sticks-genetics.jpg"},
    {title: "In House Genetics", image: "breeders/in-house-genetics-1-2.jpg"},
    {title: "Lost River", image: "breeders/lost-river-logo.jpg"},
    {title: "Myers Creek Cannabis", image: "breeders/myers-creek-cannabis-242x242.png"},
    {title: "Neptune Seed Bank", image: "breeders/neptune-seed-bank.jpg"},
    {title: "Nine Fold Genetics", image: "breeders/nine-fold-genetics.jpg"}
]
