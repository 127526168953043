import React, {useState} from "react";

export const CheckoutContext = React.createContext({});
export default CheckoutContext;

export const CartContextProvider = ({children}) => {
    const [wizardStep, setWizardStep] = useState(1);
    const [shippingMethod, setShippingMethod] = useState("standard")

    return (
        <CheckoutContext.Provider
            value={{
                wizardStep,
                setWizardStep,
                shippingMethod,
                setShippingMethod
            }}
        >
            <>{children}</>
        </CheckoutContext.Provider>
    );
}