import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/styles";

export default function HomeFeatureTab(props) {
    const {text, selected, ...allProps} = props;
    const [hover, setHover] = useState(false);

    const onHoverIn = () => {
        setHover(true)
    }

    const onHoverOut = () => {
        setHover(false)
    }

    return (
        <div style={{display: "inline-block"}} onMouseOver={onHoverIn} onMouseOut={onHoverOut} {...allProps}>
            <TabButton selected={selected || hover}>
                <Typography variant="button" sx={{fontWeight: 700}}>
                    {text}
                </Typography>
            </TabButton>
        </div>
    )
}

const TabButton = styled('div')(({ selected }) => ({
    padding: "8px 16px 8px 16px",
    cursor: "pointer",
    color: "white",
    borderRadius: 10,
    ...(selected && {
        transition: "background .15s ease-out",
        background: "rgba(255, 255, 255, 0.2)"
    }),
}));