import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CartContextProvider} from "./cartContext";
import CartHome from "./cartHome";

export default function CartIndex(props) {
    return (
        <PublicLayout>
            <CartContextProvider>
                <CartHome/>
            </CartContextProvider>
        </PublicLayout>
    );
}