import React, {useState} from "react";
import {styled} from "@mui/styles";
import {Divider, Stack} from "@mui/material";
import {Check} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../../../components/platform/platformSpace";
import Grid2 from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const REWARDS = [
    {
        label: "Monthly price",
        plans: [
            {value: "$6.99"},
            {value: "$9.99"},
            {value: "$19.99"}
        ]
    },
    {
        label: "Access to discord channels",
        plans: [
            {included: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Free shipping",
        plans: [
            {included: false},
            {included: false},
            {included: true}
        ]
    },
    {
        label: "Exclusive discounts",
        plans: [
            {included: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Early access to drops and presales",
        plans: [
            {excluded: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Group chat and discussion forums",
        plans: [
            {included: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Product giveaways",
        plans: [
            {included: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Access to auctions",
        plans: [
            {included: true},
            {included: true},
            {included: true}
        ]
    },
    {
        label: "Ask the breeder, direct messaging",
        plans: [
            {excluded: true},
            {excluded: true},
            {included: true}
        ]
    },
    {
        label: "Access to ultra premium seeds",
        plans: [
            {excluded: true},
            {excluded: true},
            {included: true}
        ]
    }
]

export default function CompareHome(props) {
    const history = useHistory();
    const [planSelected, setPlanSelected] = useState(3);

    const onSelectPlan = (plan) => {
        setPlanSelected(plan);
    }

    const onNext = () => {
        history.push("/breeder/gasgiant/subscribe")
    }

    const getComponentOrValue = (value, selected) => {
        if (React.isValidElement(value)) {
            return  value
        }
        return value;
    }

    return (
        <CompareContainer>
            <PageTitle>Choose the plan that's right for you</PageTitle>
            <Stack direction="column" spacing={2}>
                <PlatformSpace height={15}/>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <CheckIcon/>
                    <Typography variant="h6">
                        Access to the worlds best cannabis genetics.
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <CheckIcon/>
                    <Typography variant="h6">
                        Exclusive breeder drops and auctions.
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <CheckIcon/>
                    <Typography variant="h6">
                        Change or cancel your plan anytime.
                    </Typography>
                </Stack>
            </Stack>
            <PlatformSpace height={60}/>
            <Grid2 container spacing={1}>
                <Grid2 item md={6}>
                </Grid2>
                <Grid2 item md={2}>
                    <SubscriptionTitle selected={planSelected === 1} justifyContent="center" alignItems="center" spacing={3} onClick={() => onSelectPlan(1)}>
                        <Typography variant="body3">
                            Aspiring Grower
                        </Typography>
                    </SubscriptionTitle>
                    {planSelected === 1 && <SubscriptionSelected/>}
                </Grid2>
                <Grid2 item md={2}>
                    <SubscriptionTitle selected={planSelected === 2} justifyContent="center" alignItems="center" spacing={3} onClick={() => onSelectPlan(2)}>
                        <Typography variant="body3">
                            Seasoned Pro
                        </Typography>
                    </SubscriptionTitle>
                    {planSelected === 2 && <SubscriptionSelected/>}
                </Grid2>
                <Grid2 item md={2}>
                    <SubscriptionTitle selected={planSelected === 3} justifyContent="center" alignItems="center" spacing={3} onClick={() => onSelectPlan(3)}>
                        <Typography variant="body3">
                            Master Grower
                        </Typography>
                    </SubscriptionTitle>
                    {planSelected === 3 && <SubscriptionSelected/>}
                </Grid2>
                <Grid2 item md={12}>
                    <PlatformSpace height={30}/>
                </Grid2>
                {REWARDS.map((reward, idx) => {
                    return (
                        <React.Fragment>
                            <Grid2 item md={6}>
                                <SubscriptionRewardTitle>
                                    {reward.label}
                                </SubscriptionRewardTitle>
                            </Grid2>
                            {reward.plans.map((plan, idx) => {
                                const selected = planSelected === (idx + 1);
                                return (
                                    <Grid2 item md={2} >
                                        <SubscriptionText selected={selected}>
                                            {plan.included === true && <IncludedIcon selected={selected}/>}
                                            {plan.excluded === true && "-"}
                                            {plan.value &&
                                                <React.Fragment>
                                                    {plan.value}
                                                </React.Fragment>
                                            }
                                        </SubscriptionText>
                                    </Grid2>
                                )
                            })}
                            {(idx < (REWARDS.length - 1)) &&
                                <Grid2 item md={12}>
                                    <MatrixDivider/>
                                </Grid2>
                            }
                        </React.Fragment>
                    )
                })}
            </Grid2>
            <PlatformSpace height={60}/>
            <NextContainer>
                <NextButton variant="contained" onClick={onNext}>
                    Next
                </NextButton>
            </NextContainer>
        </CompareContainer>
    );
}

const NextContainer = styled("div")(({ theme }) => ({
    width: "100% !important",
    textAlign: "center"
}));

const NextButton = styled(Button)(({ theme }) => ({
    height: 65,
    width: "50% !important",
    backgroundImage: "none !important",
}));

const SubscriptionRewardTitle = styled(Typography)(({  }) => ({
}));

const IncludedIcon = styled(Check)(({ selected }) => ({
    opacity: 0.7,
    ...(selected && {
        opacity: 1,
        color: "#1976d2 !important"
    }),
}));

const SubscriptionText = styled(Typography)(({ selected }) => ({
    textAlign: "center",
    width: "100%",
    opacity: 0.7,
    ...(selected && {
        opacity: 1,
        color: "#1976d2 !important"
    }),
}));

const SubscriptionTitle = styled(Stack)(({ selected }) => ({
    textAlign: "center",
    backgroundColor: "#1976d2",
    height: 120,
    width: 130,
    padding: 10,
    margin: "auto",
    cursor: "pointer",
    opacity: 0.7,
    ...(selected && {
        opacity: 1,
        color: "#1976d2 !important"
    }),
}));

const SubscriptionSelected = styled("div")(({}) => ({
    margin: "auto",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: "20px solid #1976d2"
}));

const MatrixDivider = styled(Divider)(({  }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));

const CompareContainer = styled("div")(({ theme }) => ({
    width: "50vw",
    margin: "60px auto 60px auto"
}));

const CheckIcon = styled(Check)(({ theme }) => ({
    fontSize: "1.5rem !important",
    color: "#1976d2 !important"
}));

const PageTitle = styled("div")(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));
