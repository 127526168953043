import * as React from 'react';
import {useRef} from 'react';
import {styled} from "@mui/styles";

export default function HomeBackgroundVideo(props) {
    const {offset} = props;
    const videoRef = useRef();

    return (
        <React.Fragment>
            <VideoPlayer ref={videoRef} autoPlay loop muted width={window.innerWidth} offset={offset} >
                <source src={`${process.env.PUBLIC_URL}/video/saturn.mp4`} type="video/mp4"/>
            </VideoPlayer>
            <VideoOverlay offset={offset}/>
        </React.Fragment>
    )
}

const VideoPlayer = styled('video')(({ offset }) => ({
    opacity: offset > 100 ? 0 : 0.7,
    zIndex: -1,
    position: "absolute",
}));

const VideoOverlay = styled('div')(({ offset }) => ({
    opacity: offset > 100 ? 0 : 1,
    width: "100%",
    height: "110%",
    zIndex: 0,
    position: "absolute",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundImage: `linear-gradient(0deg, rgba(41, 31, 55, 1),  rgba(41, 31, 55, 1), rgba(2, 2, 2, 1), rgba(2, 2, 2, 0.9), rgba(2, 2, 2, 0.1), rgba(2, 2, 2, 0.1))`
}));