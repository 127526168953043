import * as React from 'react';
import {styled} from "@mui/styles";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import PlatformSelect from "../../components/platform/platformSelect";
import {Divider, Stack} from "@mui/material";
import Button from "@mui/material/Button";

export default function CheckoutAddressForm(props) {
    return (
        <React.Fragment>
            <PlatformSelect
                fullWidth
                name="state"
                placeholder="Country/Region"
                options={[
                    {
                        "label": "California",
                        "value": "CA"
                    }
                ]}
                errorText="State is required"
            />
            <PlatformSpace height={15}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                <PlatformTextField placeholder="First name" fullWidth/>
                <PlatformTextField placeholder="Last name" fullWidth/>
            </Stack>
            <PlatformSpace height={15}/>
            <PlatformTextField placeholder="Address" fullWidth/>
            <PlatformSpace height={15}/>
            <PlatformTextField placeholder="Apartment, suite, etc. (optional)" fullWidth/>
            <PlatformSpace height={15}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                <PlatformTextField placeholder="City" fullWidth/>
                <PlatformSelect
                    fullWidth
                    name="state"
                    options={[
                        {
                            "label": "California",
                            "value": "CA"
                        }
                    ]}
                    errorText="State is required"
                />
                <PlatformTextField placeholder="Zip code" fullWidth/>
            </Stack>
            <PlatformSpace height={15}/>
            <PlatformTextField placeholder="Phone" fullWidth/>
        </React.Fragment>
    )
}

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));