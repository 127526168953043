import React from "react";
import {ButtonGroup, Divider, Stack} from "@mui/material";
import {
    AutoAwesomeMosaicOutlined,
    FilterList,
    FormatListBulleted,
    GridOn,
    Search,
    WindowOutlined
} from "@mui/icons-material";
import {styled} from "@mui/styles";
import PlatformSelect2 from "../../components/platform/platformSelect2";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import PlatformItemGridTile from "../../components/platform/platformItemGridTile";
import CatalogFilter from "./catalog/catalogFilter";
import CatalogIntRangeFilter from "./catalog/catalogIntRangeFilter";
import CatalogCheckboxGroupFilter from "./catalog/catalogCheckboxGroupFilter";
import HomeSectionBar from "../home/homeSectionBar";

export default function BreederSeeds(props) {

    const accordionProps = {
        //https://stackoverflow.com/questions/63032741/mui-expansionpanel-with-transitioncomponent-doesnt-collapse-the-item
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 0, exit: 0 }
    }

    // Icon Button style={{boxShadow: "0 0 0 2px", outlineColor: 'red', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}
    return (
        <React.Fragment>
            <Stack justifyContent="flex-start"
                   direction="row"
                   spacing={2}
                   alignItems="center">
                <span style={{width: 400, marginLeft: 15}}>
                    <Stack justifyContent="space-between"
                           direction="row"
                           alignItems="center">
                        <IconButton>
                            <FilterList/>
                        </IconButton>
                        <ResultCount>19,461 results</ResultCount>
                    </Stack>
                </span>
                <div style={{width: "100%"}}>
                    <PlatformTextField
                        sx={{
                            display: "flex",
                            '& .MuiSvgIcon-root': {
                                marginTop: 1,
                                marginLeft: 1,
                                color: "rgb(41, 31, 55) !important"
                            },
                        }}
                        placeholder="Search by name or attribute"
                        startAdornment={<Search/>}
                    />
                </div>
                <PlatformSelect2
                    fullWidth
                    name="state"
                    options={[
                        {
                            "label": "Price low to high",
                            "value": "CA"
                        },
                    ]}
                    errorText="State is required"
                />
                <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group">
                    <IconButton aria-label="delete">
                        <FormatListBulleted />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <GridOn />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <WindowOutlined />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <AutoAwesomeMosaicOutlined />
                    </IconButton>
                </ButtonGroup>
            </Stack>
            <PlatformSpace height={35}/>
            <Stack justifyContent="space-between"
                   direction="row"
                   spacing={2}
                   alignItems="flex-start">
                <div style={{width: 400, overflowY: "scroll"}}>
                    <CatalogFilter
                        title="Price"
                        content={<CatalogIntRangeFilter/>}
                    />
                    <Divider/>
                    <AccordionTitle>Attributes</AccordionTitle>
                    <CatalogFilter
                        title="Height"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Compact", "Average", "Tall"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Yield"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["XL", "Average", "High"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Flowering"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Long", "Fast", "Average"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Climate"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Long summers", "Short summers"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Flavor"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Lemon", "Orange", "Grape", "Fruit", "Gassy", "Earthy", "Hazey", "Cheesy", "Funky"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Chemotype"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["THC dominant", "Balanced THC/CBD", "CBD Dominant", "Unknown"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Phenotype"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Indica", "Sativa", "Hybrid"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="THC percent"
                        content={<CatalogIntRangeFilter/>}
                    />
                    <CatalogFilter
                        title="CBD percent"
                        content={<CatalogIntRangeFilter/>}
                    />
                    <CatalogFilter
                        title="Terpenes"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Caryophyllene","Limonene","Myrcene","Pinene","Humulene","Linalool","Terpinolene","Ocimene"]}
                            />
                        }
                    />
                    <CatalogFilter
                        title="Effects"
                        content={
                            <CatalogCheckboxGroupFilter
                                options={["Happy","Hungry","Relaxed","Euphoric","Creative","Giggly","Uplifted","Sleepy","Talkative"]}
                            />
                        }
                    />
                </div>
                <Grid container rowSpacing={3} spacing={2} >
                    {FLOWER.sort(() => 0.5 - Math.random()).map((flower) => {
                        return (
                            <Grid item>
                                <PlatformItemGridTile title={flower.title} image={flower.image} mode="commerce"/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Stack>
        </React.Fragment>
    );
}

const AccordionTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white",
    margin: "25px 10px 20px 15px"
}));

const ResultCount = styled('div')(({ theme }) => ({
    fontWeight: 400,
    minWidth: 125,
    fontSize: "1.2rem",
    color: "rgba(227,204,255,0.56)"
}));

const FilterText = styled('span')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white",
    textTransform: "lowercase",
    lineHeight: "3rem",
    margin: "0 8px 0 8px"
}));

const FLOWER = [
    {title: "Wedding Cake", image: "flower/_DDD8101_weddingcakeLiveBud.jpg", content: <HomeSectionBar/>},
    {title: "Kush Mintz", image: "flower/_DDD8130_KushMintsLiveFlower_1.jpg", content: <HomeSectionBar/>},
    {title: "Pink Runtz", image: "flower/_DDD8179_pinkruntsLIVEflower.jpg", content: <HomeSectionBar/>},
    {title: "Chem dela Chem", image: "flower/_DDD8182_chemdelachemLIVEMacro.jpg", content: <HomeSectionBar/>},
    {title: "Wedding Crasher", image: "flower/_DDD8185_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "GGG", image: "flower/_DDD8192_weddingcrasherLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Erkle", image: "flower/_DDD8203_kushmintsLiveMacro.jpg", content: <HomeSectionBar/>},
    {title: "Purple Poison", image: "flower/_DDD8264_purplepoisonLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Poison Beans", image: "flower/_DDD8274_poisonbeansLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Swaziland Gold", image: "flower/_DDD8289_swazilandgoldLIVE.jpg", content: <HomeSectionBar/>},
    {title: "Ice Cream Mints", image: "flower/_DDD8391_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Skywalker OG", image: "flower/_DDD8398_icecreammintsMACRO.jpg", content: <HomeSectionBar/>},
    {title: "Monster Kush", image: "flower/_DDD8455.jpg", content: <HomeSectionBar/>},
    {title: "Green Gelato", image: "flower/_DDD8500.jpg", content: <HomeSectionBar/>},
    {title: "Sweet Island Skunk", image: "flower/_DDD8533.jpg", content: <HomeSectionBar/>},
    {title: "Southern Gas", image: "flower/_DDD8543.jpg", content: <HomeSectionBar/>},
    {title: "Northern Lights", image: "flower/_DRW8561.jpg", content: <HomeSectionBar/>},
    {title: "White Widow", image: "flower/_DRW8616.jpg", content: <HomeSectionBar/>},
    {title: "Kush Cream Mints", image: "flower/_DRW8618.jpg", content: <HomeSectionBar/>}
]