import React from "react";

export const CatalogItemContext = React.createContext({});
export default CatalogItemContext;

export const CatalogItemContextProvider = ({children}) => {

    return (
        <CatalogItemContext.Provider
            value={{

            }}
        >
            <>{children}</>
        </CatalogItemContext.Provider>
    );
}