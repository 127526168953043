import React from "react";
import cx from "classnames";
import {makeStyles} from "@mui/styles";
import mergeStyles from "../util/mergeStyles";

const PlatformLabel = (props) => {
  const classes = useStyles();
  const {children, disabled, inverted, onClick} = props;

  let combinedClassName = cx({
    [classes.disabled]: disabled,
    [classes.button]: typeof onClick === "function",
    [classes.label]: true
  });

  return (
      <label className={combinedClassName} style={mergeStyles(inverted && {color: "white"})}>{children}</label>
  );
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.5
  },
  button: {
    cursor: "pointer"
  },
  label: {
    color: "white",
    fontWeight: 400,
    fontSize: "0.9rem",
    marginLeft: 10
  },
}));

export default PlatformLabel;