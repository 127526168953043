import React from "react";
import {useHistory} from "react-router-dom";

export const DropsContext = React.createContext({});
export default DropsContext;

export const DropsContextProvider = ({children}) => {
    const history = useHistory();

    const onDrop = (id) => {
        history.push(`/drops/${id}`);
    }

    return (
        <DropsContext.Provider
            value={{
                onDrop
            }}
        >
            <>{children}</>
        </DropsContext.Provider>
    );
}