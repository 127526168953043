import React, {useRef, useState} from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import {NotificationsOutlined} from "@mui/icons-material";

export default function DropsGridTile(props) {
    const {title, image, mode = "tile"} = props;
    const containerRef = useRef(null);
    const [hover, setHover] = useState(false);
    const [hoverAlert, setHoverAlert] = useState(false);

    const onMouseIn = () => {
        setHover(true);
    }

    const onMouseOut = () => {
        setHover(false);
    }

    const onMouseInAlert = () => {
        setHoverAlert(true);
    }

    const onMouseOutAlert = () => {
        setHoverAlert(false);
    }

    return (
        <TileContainer ref={containerRef} onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}
                       mode={mode} hover={hover}>
            <ImageContainer mode={mode} hover={hover}>
                <Image src={`${process.env.PUBLIC_URL}/images/${image}`} />
                {hover &&
                    <AlertsButton hoverAlert={hoverAlert} onMouseEnter={onMouseInAlert} onMouseLeave={onMouseOutAlert}>
                        <NotificationsOutlined fontSize="medium" sx={{color: !hoverAlert ? "white" : "black"}} />
                    </AlertsButton>
                }
            </ImageContainer>
            <ContentContainer>
                <Stack spacing={1}>
                    <Title>{title}</Title>
                    <SubTitle style={{}}>Indica</SubTitle>
                    <Line1>Indoor yield: 400/g x m²</Line1>
                </Stack>
            </ContentContainer>
        </TileContainer>
    );
}

const AlertsButton = styled(Button)(({ selected, hoverAlert }) => ({
    textAlign: "center",
    cursor: "pointer",
    backdropFilter: "blur(20px)",
    position: "absolute !important",
    width: "50px !important",
    height: "50px !important",
    minWidth: "0 !important",
    padding: "0 !important",
    right: 10,
    top: 10,
    borderRadius: "50% !important",
    background: "rgba(255, 255, 255, 0.2) !important",
    ...(hoverAlert && {
        background: "rgba(255, 255, 255, 1) !important",
    }),
}));

const TileContainer = styled('div')(({ mode, hover }) => ({
    zIndex: 2,
    position: "relative",
    cursor: "pointer",
    boxShadow: "rgba(183, 132, 255, 0.25) 0px 6px 25px 0px",
    borderRadius: 12,
    width: "15vw",
    overflow: "hidden",
    transition: ".3s ease-in-out",
    ...(mode === "tile" && {
        transform: !hover ? "translateY(0rem)" : "translateY(-0.4rem)",
    }),
}));

const ImageContainer = styled('div')(({ mode, hover, height }) => ({
    zIndex: 1,
    height: "16vh",
    position: "relative",
    borderRadius: "12px 12px 0 0",
    transition: ".3s ease-in-out",
    ...(mode === "commerce" && {
        transform: !hover ? "scale(1)" : "scale(1.1)",
    }),
}));

const Image = styled('img')(({ theme }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: 250,
    width: "100%",
    borderRadius: 20,
    pointerEvents: "none"
}));

const ContentContainer = styled('div')(({ theme }) => ({
    backgroundColor: "rgb(59,42,84)",
    width: "100%",
    height: "100%",
    position: "relative",
    padding: 15,
    zIndex: 6001
}));

const Title = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white"
}));

const SubTitle = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.2rem",
    color: "white"
}));

const Line1 = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.0rem",
    color: "rgb(100, 109, 117)"
}));

//http://bideowego.com/css3-border-radius-transitions
//https://stackoverflow.com/questions/31693219/issue-while-using-transitions-opacity-change-overflow-hidden/31698580#31698580