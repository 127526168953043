import React, {useState} from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import {Close, Search} from "@mui/icons-material";
import {TextField} from "@mui/material";

export default function PlatformSearchField2(props) {
    const {...allProps} = props;
    const [hover, setHover] = useState(false);
    const [focused, setFocused] = useState(false)

    const onMouseOver = () => {
        setHover(true);
    }

    const onMouseOut = () => {
        if (!focused) {
            setHover(false);
        }
    }

    const onBlur = () => {
        setHover(false);
        setFocused(false);
    }

    const onFocus = () => setFocused(true);

    return (
        <TextField
            {...allProps}
            placeholder="Search cultivars, breeders, and flavors"
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onBlur={onBlur}
            variant="outlined"
            sx={{
                '& .MuiSvgIcon-root': {
                    color: hover ? "black !important" : "white !important"
                },
                '& .MuiInputAdornment-root': {
                    transition: "background .3s ease-out",
                    backgroundColor: hover ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.0)",
                    marginRight: 0
                },
                '& .MuiInputAdornment-positionStart': {
                    padding: "21.5px 12px",
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                },
                '& .MuiInputAdornment-positionEnd': {
                    padding: "21.5px 12px",
                    borderTopRightRadius: 12,
                    borderBottomRightRadius: 12,
                    marginLeft: 0
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                },
                "& .MuiOutlinedInput-root": {
                    padding: 0,
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "12px !important",
                },
                "& .MuiOutlinedInput-input": {
                    transition: "background .3s ease-out",
                    backgroundColor: hover ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.0)",
                    borderRadius: 0,
                    color: hover ? "black !important" : "white !important",
                }
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"
                                    onFocus={onFocus}
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    onBlur={onBlur}>
                        <Search />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end"
                                    onFocus={onFocus}
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    onBlur={onBlur}>
                        <Close />
                    </InputAdornment>
                ),
            }}
        />
    );
}