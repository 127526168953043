import * as React from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import Button from "@mui/material/Button";
import CheckoutHeader from "./checkoutHeader";
import CheckoutCrumbs from "./checkoutCrumbs";
import CheckoutStatus from "./checkoutStatus";
import CheckoutControls from "./checkoutControls";
import CheckoutFooter from "./checkoutFooter";
import CheckoutPaymentForm from "./checkoutPaymentForm";
import {CartAccordionContextProvider} from "./checkoutPaymentFormContext";
import CheckoutAddressForm from "./checkoutAddressForm";

export default function CheckoutStep3Payment(props) {
    return (
        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height: "90vh !important"}}>
            <div style={{width: "100%"}}>
                <CheckoutHeader/>
                <CheckoutCrumbs/>
                <PlatformSpace height={30}/>
                <CheckoutStatus/>
                <PlatformSpace height={40}/>
                <Typography variant="h8">
                    Payment
                </Typography>
                <PlatformSpace height={5}/>
                <Subtitle>
                    All transactions are secure and encrypted.
                </Subtitle>
                <PlatformSpace height={15}/>
                <CartAccordionContextProvider defaultOptionType="creditCard">
                    <CheckoutPaymentForm
                        name="creditCard"
                        topRow
                        header={
                            <Typography variant="body1">
                                Credit card
                            </Typography>
                        }
                        content={
                            <div>
                                <PlatformTextField placeholder="Card number" fullWidth/>
                                <PlatformSpace height={15}/>
                                <PlatformTextField placeholder="Name on card" fullWidth/>
                                <PlatformSpace height={15}/>
                                <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                    <PlatformTextField placeholder="Expiration date (MM / YY)" fullWidth/>
                                    <PlatformTextField placeholder="Security code" fullWidth/>
                                </Stack>
                                <PlatformSpace height={15}/>
                            </div>
                        }
                    />
                    <CheckoutPaymentForm
                        name="paypal"
                        bottomRow
                        header={
                            <Typography variant="body1">
                                PayPal
                            </Typography>
                        }
                        content={
                            <div>
                                Not available
                            </div>
                        }
                    />
                </CartAccordionContextProvider>
                <PlatformSpace height={40}/>
                <Typography variant="h8">
                    Billing address
                </Typography>
                <PlatformSpace height={5}/>
                <Subtitle>
                    Select the address that matches your card or payment method.
                </Subtitle>
                <PlatformSpace height={15}/>
                <CartAccordionContextProvider defaultOptionType="shippingAddress">
                    <CheckoutPaymentForm
                        name="shippingAddress"
                        topRow
                        header={
                            <div>
                                <Typography variant="body1">
                                    Same as shipping address
                                </Typography>
                            </div>
                        }
                        content={null}
                    />
                    <CheckoutPaymentForm
                        name="newAddress"
                        bottomRow
                        header={
                            <div>
                                <Typography variant="body1">
                                    Use a different billing address
                                </Typography>
                            </div>
                        }
                        content={
                            <CheckoutAddressForm/>
                        }
                    />
                </CartAccordionContextProvider>
                <PlatformSpace height={30}/>
                <CheckoutControls/>
            </div>
            <CheckoutFooter/>
        </Stack>
    )
}

const Subtitle = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const ViewBreederButton = styled(Button)(({ theme }) => ({
    width: "200px !important",
    border: "1px solid grey",
    borderRadius: 20,
    padding: "15px !important",
    backgroundImage: "none !important",
    margin: "auto !important"
}));
