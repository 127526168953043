import API from "api";

export default {

    getCollection(handle, parameters, successCallback, errorCallback) {
        API.POST({
            url: `/cms/collection/${handle}`,
            params: {}
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}