import React, {useState} from "react";

export const CompareContext = React.createContext({});
export default CompareContext;

export const CompareContextProvider = ({children}) => {
    const [wizardStep, setWizardStep] = useState(1);

    return (
        <CompareContext.Provider
            value={{
                wizardStep,
                setWizardStep
            }}
        >
            <>{children}</>
        </CompareContext.Provider>
    );
}