import React from "react";
import {styled} from "@mui/styles";
import Grid from '@mui/material/Unstable_Grid2';
import PlatformSpace from "../../components/platform/platformSpace";

export default function HomeSectionBar(props) {
    return (
        <Grid container spacing={1}>
            <Grid xs={12} md={12}>
                <PlatformSpace height={10}/>
            </Grid>
            <Grid xs={6} md={6}>
                <DivTitle>Phenotype</DivTitle>
            </Grid>
            <Grid xs={6} md={6}>
                <DivTitle>Avg. THC</DivTitle>
            </Grid>
            <Grid xs={6} md={6}>
                <DivValue>Hybrid</DivValue>
            </Grid>
            <Grid xs={6} md={6}>
                <DivValue>23% to 27%</DivValue>
            </Grid>
        </Grid>
    )
}
const DivTitle = styled('div')(({ theme }) => ({
    color: "rgb(112, 122, 131)",
    fontWeight: 600,
    fontSize: "0.8rem",
    textTransform: "uppercase",
}));

const DivValue = styled('div')(({ theme }) => ({
    color: "white",
    fontWeight: 600,
    fontSize: "1.0rem",
    textTransform: "uppercase",
}));