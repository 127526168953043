import React, {useRef, useState} from 'react';
import {Stack} from "@mui/material";
import PlatformCartSplitButton from "./platformCartSplitButton";
import {styled} from "@mui/styles";

export default function PlatformItemGridTile(props) {
    const {title, image, mode = "tile", width = "12vw", height = "12vw"} = props;
    const containerRef = useRef(null);
    const [hover, setHover] = useState(false);

    const onMouseIn = () => {
        setHover(true);
    }

    const onMouseOut = () => {
        setHover(false);
    }

    return (
        <TileContainer style={{width: width}} ref={containerRef} onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}
                       mode={mode} hover={hover}>
            <ImageContainer height={height} mode={mode} hover={hover}>
                <Image src={`${process.env.PUBLIC_URL}/images/${image}`} />
            </ImageContainer>
            <ContentContainer>
                <Stack spacing={1}>
                    <Title>{title}</Title>
                    <SubTitle style={{}}>Indica</SubTitle>
                    <Line1>Indoor yield: 400/g x m²</Line1>
                </Stack>
                {mode === "commerce" &&
                    <PlatformCartSplitButton
                        height={35}
                        tiled
                        absolute
                        containerRef={containerRef}
                        hover={hover}
                        hoverHandler={(hover) => setHover(hover)}
                    />
                }
            </ContentContainer>
        </TileContainer>
    );
}

const TileContainer = styled('div')(({ mode, hover }) => ({
    zIndex: 2,
    position: "relative",
    cursor: "pointer",
    boxShadow: "rgba(183, 132, 255, 0.15) 0px 6px 25px 0px",
    borderRadius: 12,
    width: "100%",
    overflow: "hidden",
    transition: ".3s ease-in-out",
    ...(mode === "tile" && {
        transform: !hover ? "translateY(0rem)" : "translateY(-0.4rem)",
    }),
}));

const ImageContainer = styled('div')(({ mode, hover, height }) => ({
    zIndex: 1,
    height: 150,
    position: "relative",
    borderRadius: "12px 12px 0 0",
    transition: ".3s ease-in-out",
    ...(mode === "commerce" && {
        transform: !hover ? "scale(1)" : "scale(1.1)",
    }),
}));

const Image = styled('img')(({ theme }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: 150,
    width: "100%",
    borderRadius: "20px 20px 0 0",
    pointerEvents: "none"
}));

const ContentContainer = styled('div')(({ theme }) => ({
    backgroundColor: "rgb(59,42,84)",
    width: "100%",
    height: 115,
    position: "relative",
    zIndex: 6001
}));

const Title = styled('div')(({ theme }) => ({
    padding: "15px 0 0 20px",
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "white"
}));

const SubTitle = styled('div')(({ theme }) => ({
    padding: "0 0 0 20px",
    fontWeight: 400,
    fontSize: "1.2rem",
    color: "white"
}));

const Line1 = styled('div')(({ theme }) => ({
    padding: "0 0 0 20px",
    fontWeight: 400,
    fontSize: "1.0rem",
    color: "rgb(100, 109, 117)"
}));

//http://bideowego.com/css3-border-radius-transitions
//https://stackoverflow.com/questions/31693219/issue-while-using-transitions-opacity-change-overflow-hidden/31698580#31698580