import * as React from 'react';
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function SubscribeSummary(props) {

    return (
        <div style={{width: "23vw"}}>
            <PlatformSpace height={30}/>
            <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                <img height={175} src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}
                     style={{border: "1px solid white", borderRadius: 4, height: 60}}/>
                <BrandTitle>
                    Gas Giant
                    <div>
                        <Typography variant="body4">
                            Master Grower
                        </Typography>
                    </div>
                </BrandTitle>
            </Stack>

            <PlatformSpace height={30}/>
            <SubscribeDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Monthly payment
                </TotalsText>
                <TotalsText>
                    $19.99
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Sales tax
                </TotalsText>
                <TotalsSubtext>
                    $0.83
                </TotalsSubtext>
            </Stack>
            <PlatformSpace height={5}/>
            <SubscribeDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <Typography variant="h7">
                    Total due today
                </Typography>
                <Stack spacing={2} justifyContent="flex-end" direction="row" alignItems="center">
                    <TotalsSubtext>
                        USD
                    </TotalsSubtext>
                    <Typography variant="h7">
                        $20.82
                    </Typography>
                </Stack>
            </Stack>
        </div>
    )
}

const BrandTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));

const CheckoutButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: 400
}));

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const SubscribeDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));