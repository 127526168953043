import React, {useState} from "react"
import {useKeenSlider} from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "./platformNetflixSlider.css"
import PlatformNetflixSliderItem from "./platformNetflixSliderItem";
import {styled} from "@mui/styles";

export default function PlatformNetflixSlider(props) {
    const {title, slides, perView, itemStyle, itemSize, sliderComponent, changeHandler = () => {}, autoStart = true} = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const SliderItem = sliderComponent || PlatformNetflixSliderItem;

    const [sliderRef, instanceRef] = useKeenSlider({
            loop: false,
            initial: 0,
            slides: {
                perView: perView || 4,
                spacing: 15,
            },
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
                changeHandler(slider.track.details.rel);
            },
            created() {
                setLoaded(true);
            },
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 3000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true;
                        clearNextTimeout();
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false;
                        {autoStart && nextTimeout()};
                    })
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]
    )

    return (
        <SliderContainer>
            {title && <SliderTitle>{title}</SliderTitle>}
            <div ref={sliderRef} className="keen-slider">
                {slides.map((slide, idx) => {
                    return (
                        <div key={idx} className="keen-slider__slide">
                            <SliderItem
                                size={itemSize}
                                itemStyle={itemStyle}
                                title={slide.title}
                                content={slide.content}
                                video={slide.video ? `${process.env.PUBLIC_URL}${slide.video}` : null}
                                image={slide.image ? `${process.env.PUBLIC_URL}/images/${slide.image}` : null}
                            />
                        </div>
                    )
                })}
            </div>
            {loaded && instanceRef.current && (
                <React.Fragment>
                    <Arrow
                        left
                        onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                    />
                    <Arrow
                        onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                        }
                    />
                </React.Fragment>
            )}
        </SliderContainer>
    )
}

const SliderContainer = styled('div')(({ theme }) => ({
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    padding: "0 45px 0 30px"
}));

const SliderTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
    margin: "20px 0 40px 0"
}));

function Arrow(props) {
    const {onClick, disabled, left} = props;

    return (
        <svg
            onClick={onClick}
            className={`arrow ${left ? "arrow--left" : "arrow--right"} ${disabled ? " arrow--disabled" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            {left && (<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>)}
            {!left && (<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>)}
        </svg>
    )
}