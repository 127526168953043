import {useEffect, useState} from 'react';

export default function useScrollOffset() {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        // Adding the event listener when the component mounts
        window.addEventListener('scroll', onScroll, { passive: true });

        // Removing the event listener when the component unmounts
        return () => window.removeEventListener('scroll', onScroll);
    }, []); // Empty dependency array ensures this effect runs only once

    return offset;
}