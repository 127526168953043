import React from "react";
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../platform/platformSpace";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Facebook, Instagram, Reddit, Twitter, YouTube} from "@mui/icons-material";
import PlatformTextField from "../platform/platformTextField";
import Button from "@mui/material/Button";

export default function PublicFooter(props) {

    return (
        <React.Fragment>
            <PlatformSpace height={40}/>
            <FooterContainer>
                <Footer>
                    <Grid container>
                        <Grid item md={5}>
                            <Typography variant="h6">
                                Stay in the loop
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Typography variant="body3">
                                Join our mailing list to stay in the loop with our newest feature releases, NFT drops,
                                and tips and tricks for navigating OpenSea.
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Stack direction="row" spacing={2}>
                                <PlatformTextField sx={{width: 500}}/>
                                <SignUpButton variant="contained">Sign up</SignUpButton>
                            </Stack>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="h6">
                                Join the community
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Stack justifyContent="flex-start"
                                   direction="row"
                                   spacing={2}
                                   alignItems="center">
                                <IconWrapper><Twitter/></IconWrapper>
                                <IconWrapper><Instagram/></IconWrapper>
                                <IconWrapper><Facebook/></IconWrapper>
                                <IconWrapper><YouTube/></IconWrapper>
                                <IconWrapper><Reddit/></IconWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                    <PlatformSpace height={30}/>
                    <Divider sx={{borderColor: "#9861ea"}}/>
                    <PlatformSpace height={60}/>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography variant="h6">
                                Phenoverse
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Typography variant="body3">
                                The world’s first and largest digital marketplace for crypto collectibles and
                                non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.
                            </Typography>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item md={2}>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    Marketplace
                                </Typography>
                                <PlatformSpace height={5}/>
                                <Typography variant="body4">
                                    Test 1
                                </Typography>
                                <Typography variant="body4">
                                    Test 2
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={2}>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    My Account
                                </Typography>
                                <PlatformSpace height={5}/>
                                <Typography variant="body4">
                                    Test 1
                                </Typography>
                                <Typography variant="body4">
                                    Test 2
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={2}>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    Resources
                                </Typography>
                                <PlatformSpace height={5}/>
                                <Typography variant="body4">
                                    Test 1
                                </Typography>
                                <Typography variant="body4">
                                    Test 2
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={2}>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    Resources
                                </Typography>
                                <PlatformSpace height={5}/>
                                <Typography variant="body4">
                                    Test 1
                                </Typography>
                                <Typography variant="body4">
                                    Test 2
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <PlatformSpace height={30}/>
                    <Divider sx={{borderColor: "#9861ea"}}/>
                    <PlatformSpace height={20}/>
                    <Stack spacing={2} direction="row"
                           justifyContent="space-between">
                        <div>
                            <Typography variant="body5">
                                © 2018 - 2023 Phenoverse, Inc
                            </Typography>
                        </div>
                        <Stack spacing={2} direction="row"
                               justifyContent="flex-end">
                            <Typography variant="body5">
                                Privacy Policy
                            </Typography>
                            <Typography variant="body5">
                                Terms of Service
                            </Typography>
                        </Stack>
                    </Stack>
                </Footer>
            </FooterContainer>
        </React.Fragment>
    )
}

const SignUpButton = styled(Button)(({ theme }) => ({
    width: "140px !important",
    //backgroundImage: "none !important",
    //backgroundColor: "#9861ea",
}));

const IconWrapper = styled('div')(({ scrolled }) => ({
    padding: "14px 14px 8px 14px",
    borderRadius: 12,
    backgroundColor: "#9861ea",
}));

const FooterContainer = styled('div')(({ selected }) => ({
    width: "100%",
    backgroundColor: "#593e80",
}));

const Footer = styled('div')(({ scrolled }) => ({
    margin: "auto",
    padding: 45
}));