import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {styled} from "@mui/styles";


export default function PlatformTabs(props) {
    const {tabs, fontSize, width, centered, scrollTo} = props;
    const [value, setValue] = useState("0");

    const onTabChange = (event, newValue) => {
        setValue(newValue);

        setTimeout(() => {
            window.scrollTo({
                top: scrollTo - 120,
                behavior: "smooth",
            });
        }, 50)
    };

    return (
        <TabContainer width={width}>
            <TabContext value={value}>
                <Box sx={{borderBottom: "1px solid rgba(172, 186, 197, 0.2)"}}>
                    <TabList onChange={onTabChange} aria-label="Top charts" centered={centered}>
                        {tabs.map((tab, idx) => (
                            <Tab sx={{fontSize: `${(fontSize || "1.6rem")} !important`}} key={`${tab.label}-${idx}`} label={tab.label} value={String(idx)} />
                        ))}
                    </TabList>
                </Box>
                {tabs.map((tab, idx) => (
                    <TabPanel key={`${tab.label}-${idx}`} value={String(idx)} sx={{padding: "24px 0 0 0"}}>
                        {tab.content}
                    </TabPanel>
                ))}
            </TabContext>
        </TabContainer>
    )
}

const TabContainer = styled('div')(({ width }) => ({
    width: "100vw",
    paddingLeft: 30,
    paddingRight: 45
}));