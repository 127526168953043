import * as React from 'react';
import {useContext, useEffect} from 'react';
import PlatformNetflixSlider from "../../components/platform/platformNetflixSlider";
import DropsBannerTile from "./dropsBannerTile";
import DropsBackgroundVideo from "./dropsBackgroundVideo";
import AppContext from "../../appContext";

const SLIDES = [
    {
        title: "Wedding Cake",
        video: "/images/drops/high.mp4"
    },
    {
        title: "Wedding Cake",
        video: "/images/drops/high2.mp4"
    }
]

export default function DropsBanner(props) {
    const appContext = useContext(AppContext);

    useEffect(() => {
        loadVideo(0);
    }, [])

    const loadVideo = (idx) => {
        appContext.onLayoutOptions({
            backgroundComponent: DropsBackgroundVideo,
            video: SLIDES[idx].video,
            blur: "10px"
        });
    }

    const onChange = (slide) => {
        loadVideo(slide);
    }

    return (
        <PlatformNetflixSlider
            autoStart={false}
            perView={1}
            changeHandler={onChange}
            sliderComponent={DropsBannerTile}
            slides={SLIDES}
        />
    )
}