import React from "react";

export const DropContext = React.createContext({});
export default DropContext;

export const DropContextProvider = ({children}) => {

    return (
        <DropContext.Provider
            value={{

            }}
        >
            <>{children}</>
        </DropContext.Provider>
    );
}