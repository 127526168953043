import React, {useRef} from "react";
import {ForceGraph3D} from 'react-force-graph';
import {Stack} from "@mui/material";

export default function CatalogItemLineage(props) {
    const {width} = props;
    const fgRef = useRef();
    const data = genRandomTree();
    const {devicePixelRatio: ratio = 1} = window;

    return (
        <Stack justifyContent="center" alignItems="center">
            <ForceGraph3D
                width={(width - 200) / devicePixelRatio}
                height={300}
                ref={fgRef}
                graphData={data}
                backgroundColor="rgb(41, 31, 55)"
                linkDirectionalParticleColor={() => 'red'}
                linkDirectionalParticleWidth={6}
                linkHoverPrecision={10}
                onLinkClick={link => fgRef.current.emitParticle(link)}
            />
        </Stack>
    );
}

function genRandomTree(N = 300, reverse = false) {
    return {
        nodes: [...Array(N).keys()].map(i => ({ id: i })),
        links: [...Array(N).keys()]
            .filter(id => id)
            .map(id => ({
                [reverse ? 'target' : 'source']: id,
                [reverse ? 'source' : 'target']: Math.round(Math.random() * (id-1))
            }))
    };
}