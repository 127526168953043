import * as React from 'react';
import {useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import PublicContext from "./publicContext";
import {styled} from "@mui/styles";
import {Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import PlatformSpace from "../platform/platformSpace";
import {useHistory} from "react-router-dom";
import Badge from "@mui/material/Badge";

export default function PublicCart() {
    const {showCart, setShowCart} = useContext(PublicContext);
    const history = useHistory();

    const onCart = () => {
        history.push("/checkout/123");
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowCart(open);
    };

    return (
        <Drawer
            transitionDuration={{
                appear: 225,
                enter: 225,
                exit: 100
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "rgba(0, 0, 0, 0) !important",
                    borderRadius: 4,
                    padding: 3,
                },
            }}
            sx={{
                zIndex: 10000
            }}
            anchor="right"
            open={showCart}
            onClose={toggleDrawer( false)}>
            <CartContainer>
                <CartHeader>
                    <Stack justifyContent="space-between" direction="row">
                        <Typography variant="h7" sx={{color: "black"}}>
                            Your cart
                        </Typography>
                        <IconButton sx={{height: 25}}>
                            <Close sx={{color: "black"}} fontSize="medium" />
                        </IconButton>
                    </Stack>
                </CartHeader>
                <CartDivider/>
                <CartContent>
                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                        <Typography variant="body3" sx={{color: "black"}}>
                            1 item
                        </Typography>
                        <Typography variant="body4" sx={{color: "black"}}>
                            Clear all
                        </Typography>
                    </Stack>
                    <PlatformSpace height={30}/>
                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                        <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                            <Badge badgeContent={4} color="primary">
                                <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                            </Badge>
                            <div>
                                <Typography variant="body4" sx={{color: "black"}}>
                                    Triple G Automatic
                                </Typography>
                                <br/>
                                <Typography variant="body5" sx={{color: "black"}}>
                                    30 seeds (3 packs x 5)
                                </Typography>
                            </div>
                        </Stack>
                        <Typography variant="body5" sx={{color: "black"}}>
                            $150.00
                        </Typography>
                    </Stack>
                    <PlatformSpace height={30}/>
                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                        <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                            <Badge badgeContent={9} color="primary">
                                <Image src={`${process.env.PUBLIC_URL}/images/flower/_DDD8179_pinkruntsLIVEflower.jpg`} />
                            </Badge>
                            <div>
                                <Typography variant="body4" sx={{color: "black"}}>
                                    Triple G Automatic
                                </Typography>
                                <br/>
                                <Typography variant="body5" sx={{color: "black"}}>
                                    30 seeds (3 packs x 5)
                                </Typography>
                            </div>
                        </Stack>
                        <Typography variant="body5" sx={{color: "black"}}>
                            $150.00
                        </Typography>
                    </Stack>
                </CartContent>
                <CartDivider/>
                <CartContent>
                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                        <Typography variant="body3" sx={{color: "black"}}>
                            Total price
                        </Typography>
                        <Typography variant="body3" sx={{color: "black"}}>
                            $150.00
                        </Typography>
                    </Stack>
                </CartContent>
                <PlatformSpace height={30}/>
                <CartContent>
                    <CartButton variant="contained" onClick={onCart}>
                        Complete purchase
                    </CartButton>
                </CartContent>
            </CartContainer>
        </Drawer>
    );
}

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none"
}));

const CartButton = styled(Button)(({ theme }) => ({
    height: 65,
    width: "100% !important",
    backgroundImage: "none !important",
}));

const CartHeader = styled('div')(({ fullWidth }) => ({
    padding: "30px 25px 10px 25px"
}));

const CartContent = styled('div')(({ fullWidth }) => ({
    padding: "10px 25px 10px 25px"
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(0, 0, 0, 0.1) !important"
}));

const CartContainer = styled('div')(({ fullWidth }) => ({
    minWidth: "20vw",
    height: "100vw",
    backgroundColor: "rgba(255, 255, 255, 255) !important",
    //backgroundColor: "rgba(41, 31, 55, 1)",
    borderRadius: 20
}));