import * as React from 'react';
import {useContext} from 'react';
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import {Divider, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import CheckoutContext from "./checkoutContext";
import CheckoutCrumbs from "./checkoutCrumbs";
import CheckoutHeader from "./checkoutHeader";
import CheckoutStatus from "./checkoutStatus";
import CheckoutFooter from "./checkoutFooter";
import CheckoutControls from "./checkoutControls";
import Radio from "@mui/material/Radio";

export default function CheckoutStep2Shipping(props) {
    const {shippingMethod, setShippingMethod} = useContext(CheckoutContext);

    const onShippingMethod = (shippingMethod) => {
        setShippingMethod(shippingMethod);
    }

    return (
        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height: "90vh !important"}}>
            <div style={{width: "100%"}}>
                <CheckoutHeader/>
                <CheckoutCrumbs/>
                <PlatformSpace height={30}/>
                <CheckoutStatus/>
                <PlatformSpace height={40}/>
                <Typography variant="h8">
                    Shipping method
                </Typography>
                <PlatformSpace height={10}/>
                <ShippingContainer onClick={() => onShippingMethod("standard")}>
                    <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                        <Radio checked={shippingMethod === "standard"}/>
                        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
                            <div>
                                <AttributeTitle>Standard</AttributeTitle>
                                <AttributeValue>Estimated delivery Friday, Jun 9–Monday, Jun 12</AttributeValue>
                            </div>
                            <Typography variant="body2">
                                Free
                            </Typography>
                        </Stack>
                    </Stack>
                </ShippingContainer>
                <PlatformSpace height={5}/>
                <ShippingContainer onClick={() => onShippingMethod("upsGround")}>
                    <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                        <Radio checked={shippingMethod === "upsGround"}/>
                        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
                            <div>
                                <AttributeTitle>UPS Ground</AttributeTitle>
                                <AttributeValue>3-10 business days</AttributeValue>
                            </div>
                            <Typography variant="body2">
                                $32.70
                            </Typography>
                        </Stack>
                    </Stack>
                </ShippingContainer>
                <PlatformSpace height={30}/>
                <CheckoutControls/>
            </div>
            <CheckoutFooter/>
        </Stack>
    )
}

const ShippingContainer = styled('div')(({ theme }) => ({
    backgroundColor: "rgba(70,50,95,0.5)",
    border: "1px solid #1565c0",
    padding: 15,
    borderRadius: 4,
    width: "100%",
    cursor: "pointer"
}));

const AttributeTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "0.8rem",
    color: "rgba(221,195,255,0.5)",
    textTransform: "uppercase"
}));

const AttributeValue = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.1rem",
    color: "rgba(255,255,255,0.85)",
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));