import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {SubscribeContextProvider} from "./subscribeContext";
import SubscribeHome from "./subscribeHome";

export default function SubscribeIndex(props) {
    return (
        <PublicLayout>
            <SubscribeContextProvider>
                <SubscribeHome/>
            </SubscribeContextProvider>
        </PublicLayout>
    );
}