import React, {useEffect, useState} from "react";

export const PublicContext = React.createContext({});
export default PublicContext;

export const PublicContextProvider = ({children}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [showCart, setShowCart] = useState(false);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <PublicContext.Provider
            value={{
                width,
                height,
                showCart,
                setShowCart
            }}
        >
            <>{children}</>
        </PublicContext.Provider>
    );
}