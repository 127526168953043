import * as React from 'react';
import {useState} from 'react';
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import {ArrowBackIos, Lock} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import CheckoutPaymentForm from "../../checkout/checkoutPaymentForm";
import PlatformTextField from "../../../components/platform/platformTextField";
import {CartAccordionContextProvider} from "../../checkout/checkoutPaymentFormContext";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";

export default function SubscribePayment(props) {
    const [paymentCard, setPaymentCard] = useState("masterCard");
    
    const onPaymentCard = (cardId) => {
        
    }

    const onBack = () => {
    }

    return (
        <div style={{width: "100%"}}>
            <Typography variant="h8">
                Payment details
            </Typography>
            <PlatformSpace height={30}/>
            <ShippingContainer onClick={() => onPaymentCard("standard")}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                    <Radio checked={paymentCard === "standard"}/>
                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
                        <div>
                            <AttributeTitle>Mastercard</AttributeTitle>
                            <AttributeValue>7704</AttributeValue>
                        </div>
                    </Stack>
                </Stack>
            </ShippingContainer>
            <PlatformSpace height={5}/>
            <ShippingContainer onClick={() => onPaymentCard("upsGround")}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                    <Radio checked={paymentCard === "upsGround"}/>
                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
                        <div>
                            <AttributeTitle>Visa</AttributeTitle>
                            <AttributeValue>2393</AttributeValue>
                        </div>
                    </Stack>
                </Stack>
            </ShippingContainer>
            <PlatformSpace height={20}/>
            <Typography variant="body1">
                + Add new payment method
            </Typography>
            <PlatformSpace height={20}/>
            <CartAccordionContextProvider defaultOptionType="creditCard">
                <CheckoutPaymentForm
                    name="creditCard"
                    topRow
                    header={
                        <div>
                            <Typography variant="body1">
                                Credit card
                            </Typography>
                        </div>
                    }
                    content={
                        <div>
                            <PlatformTextField placeholder="Card number" fullWidth/>
                            <PlatformSpace height={15}/>
                            <PlatformTextField placeholder="Name on card" fullWidth/>
                            <PlatformSpace height={15}/>
                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                <PlatformTextField placeholder="Expiration date (MM / YY)" fullWidth/>
                                <PlatformTextField placeholder="Security code" fullWidth/>
                            </Stack>
                            <PlatformSpace height={15}/>
                        </div>
                    }
                />
                <CheckoutPaymentForm
                    name="paypal"
                    bottomRow
                    header={
                        <div>
                            <Typography variant="body1">
                                PayPal
                            </Typography>
                        </div>
                    }
                    content={
                        <div>
                            <PlatformTextField placeholder="Card number" fullWidth/>
                            <PlatformSpace height={15}/>
                            <PlatformTextField placeholder="Name on card" fullWidth/>
                            <PlatformSpace height={15}/>
                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                <PlatformTextField placeholder="Expiration date (MM / YY)" fullWidth/>
                                <PlatformTextField placeholder="Security code" fullWidth/>
                            </Stack>
                            <PlatformSpace height={15}/>
                        </div>
                    }
                />
            </CartAccordionContextProvider>
            <PlatformSpace height={30}/>
            <Typography variant="body2">
                You’ll pay $10.83 today, and then monthly on the 1st. Your next charge will be on Jul 1.
            </Typography>
            <PlatformSpace height={10}/>
            <Typography variant="body5">
                By clicking Subscribe now, you agree to Phenoverse’s Terms of Use and Privacy Policy. This Phenoverse 
                subscription automatically renews monthly, and you’ll be notified in advance if the monthly amount
                increases. Cancel anytime in your membership settings.
            </Typography>
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                <SubscribeLink onClick={onBack}>
                    <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={1}>
                        <div>Continue browsing</div>
                    </Stack>
                </SubscribeLink>
                <CheckoutButton startIcon={<Lock/>} variant="contained">
                    Subscribe now
                </CheckoutButton>
            </Stack>
        </div>
    )
}

const ShippingContainer = styled('div')(({ theme }) => ({
    backgroundColor: "rgba(70,50,95,0.5)",
    border: "1px solid #1565c0",
    padding: 15,
    borderRadius: 4,
    width: "100%",
    cursor: "pointer"
}));

const AttributeTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "0.8rem",
    color: "rgba(221,195,255,0.5)",
    textTransform: "uppercase"
}));

const AttributeValue = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.1rem",
    color: "rgba(255,255,255,0.85)",
}));

const CheckoutButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));

const BackArrow = styled(ArrowBackIos)(({ theme }) => ({
    fontSize: "0.8rem !important",
    color: "#5492D9FF !important",
}));

const SubscribeLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ChangeLink = styled(Typography)(({  }) => ({
    color: "#5492D9FF !important",
    textDecoration: "underline",
    cursor: "pointer"
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 125,
    height: 125,
    borderRadius: "12px",
    pointerEvents: "none"
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: 400
}));

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "white",
    opacity: 0.6,
    fontWeight: 300
}));

const SubscribeDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));