import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {styled} from "@mui/styles";
import AppContext from "../../../appContext";
import PlatformSpace from "../../../components/platform/platformSpace";
import Grid from "@mui/material/Unstable_Grid2";
import {Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Facebook, Instagram, Language, MoreHoriz, StarOutline, Twitter, Verified} from "@mui/icons-material";
import PlatformTabs from "../../../components/platform/platformTabs";
import DropInfo from "./dropInfo";
import DropBackgroundVideo from "./dropBackgroundVideo";
import DropRoadmap from "./dropRoadmap";
import DropFAQ from "./dropFAQ";

export default function DropHome(props) {
    const appContext = useContext(AppContext);
    const {backgroundSize} = appContext;
    const containerRef = useRef(null);
    const [hover, setHover] = useState(false);

    const [timeRemaining, setTimeRemaining] = useState([
        {name: "DAYS", value: 0},
        {name: "HRS", value: 21},
        {name: "MINS", value: 41},
        {name: "SECS", value: 17}
    ])

    useEffect(() =>{
        appContext.onLayoutOptions({
            backgroundComponent: DropBackgroundVideo,
            video: "/images/drops/high.mp4"
        });
        return () => appContext.onLayoutReset();
    }, [])

    const onHoverIn = () => {
        setHover(true)
    }

    const onHoverOut = () => {
        setHover(false)
    }

    console.log('drop home')

    return (
        <React.Fragment>
            <TileContainer ref={containerRef} onMouseEnter={onHoverIn} onMouseLeave={onHoverOut} backgroundSize={backgroundSize}>
                <Content backgroundSize={backgroundSize}>
                    <BrandLogo height="100vh" src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}/>
                    <PlatformSpace height={25}/>
                    <Typography variant="h3">Intergalatic Planetary</Typography>
                    <PlatformSpace height={10}/>
                    <BrandTitle>
                        <Typography variant="h5">By <strong>Gas Giant</strong>
                            <VerifiedIcon sx={{fontSize: "1.2rem", color: "white !important"}}/>
                        </Typography>
                    </BrandTitle>
                    <PlatformSpace height={25}/>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Stack direction="row" spacing={1}>
                                {timeRemaining.map((time) => {
                                    return (
                                        <Countdown>
                                            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{height: 60}}>
                                                <Typography variant="body1" sx={{lineHeight: 1}}>{time.value}</Typography>
                                                <Typography variant="body2" sx={{lineHeight: 1}}>{time.name}</Typography>
                                            </Stack>
                                        </Countdown>
                                    )
                                })}
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack justifyContent="flex-end"
                                   direction="row"
                                   spacing={2}
                                   alignItems="flex-end">
                                <Language/>
                                <Instagram/>
                                <Twitter/>
                                <Facebook/>
                                <Divider orientation="vertical" flexItem />
                                <StarOutline/>
                                <MoreHoriz/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Content>
            </TileContainer>
            <PlatformTabs scrollTo={backgroundSize?.height} fontSize="1rem" tabs={[
                {label: "Info", content: <DropInfo/>},
                {label: "Roadmap", content: <DropRoadmap/> },
                {label: "FAQ", content: <DropFAQ/> }
            ]}/>
            <PlatformSpace height={100}/>
        </React.Fragment>
    )
}

const Countdown = styled('div')(({  }) => ({
    letterSpacing: 1,
    height: 60,
    width: 60,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
    fontSize: "0.8rem",
    backdropFilter: "blur(20px)",
    color: "white !important",
    borderRadius: 12,
    background: "rgba(255, 255, 255, 0.2)",
}));

const BrandLogo = styled('img')(({ theme }) => ({
    border: "3px solid white",
    borderRadius: 12
}));

const VerifiedIcon = styled(Verified)(({ theme }) => ({
    color: "rgb(32, 129, 226) !important",
    marginLeft: 5,
}));

const BrandTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.0rem",
    color: "white",
}));

const Content = styled('div')(({ theme, backgroundSize }) => ({
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30,
    bottom: 70,
    position: "absolute"
}));

const TileContainer = styled('div')(({ theme, backgroundSize }) => ({
    position: "relative",
    marginTop: backgroundSize?.height - 60,
    zIndex: 2
}));