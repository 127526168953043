import * as React from 'react';
import {useEffect, useRef} from 'react';
import {styled} from "@mui/styles";

export default function DropsBackgroundVideo(props) {
    const {offset, video} = props;
    const videoRef = useRef();

    useEffect(() => {
        videoRef.current?.load();
    }, [video]);

    return (
        <React.Fragment>
            <VideoPlayer ref={videoRef} loop muted width={window.innerWidth}>
                <source src={`${process.env.PUBLIC_URL}${video}`} type="video/mp4"/>
            </VideoPlayer>
            <VideoOverlay offset={offset}/>
        </React.Fragment>
    )
}

const VideoPlayer = styled('video')(({  }) => ({
    zIndex: -1,
    position: "absolute",
}));

const VideoOverlay = styled('div')(({  }) => ({
    width: "100%",
    height: "110%",
    zIndex: 0,
    position: "absolute",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundImage: `linear-gradient(0deg, rgba(41, 31, 55, 1),  rgba(41, 31, 55, 1), rgba(2, 2, 2, 1), rgba(2, 2, 2, 0.9), rgba(2, 2, 2, 0.1), rgba(2, 2, 2, 0.1))`
}));