import React, {useContext, useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import {ExpandMore} from "@mui/icons-material";
import DropBackgroundVideo from "./dropBackgroundVideo";
import DropFooter from "./dropFooter";
import AppContext from "../../../appContext";

const ROW = [
    {title: "What Are FishBallerz?", content: "The Shredderz is a virtual band of metal legends and musicians that has emerged from the depths of the metaverse to melt faces. With help from the Dark Lord, they aim to revolutionize metal. Fans can become crowd-sourced managers and vote on song choices, merch designs, and more. Members will receive exclusive content and priority access to future drops.<br/><br/> The Shredderz Access Pass is an evolving token that grants Day 1 Shredderz fans priority access to the Shredderz universe. Access Passes are dynamic NFTs that will transform to showcase new art and simultaneously reward fans as The Shredderz reach new streaming milestones on Spotify. \""},
    {title: "Who is Capt'n Glubber?", content: "The Shredderz is a virtual band of metal legends and musicians that has emerged from the depths of the metaverse to melt faces. With help from the Dark Lord, they aim to revolutionize metal. Fans can become crowd-sourced managers and vote on song choices, merch designs, and more. Members will receive exclusive content and priority access to future drops.<br/><br/> The Shredderz Access Pass is an evolving token that grants Day 1 Shredderz fans priority access to the Shredderz universe. Access Passes are dynamic NFTs that will transform to showcase new art and simultaneously reward fans as The Shredderz reach new streaming milestones on Spotify. \""},
    {title: "What's Mint Price?", content: "The Shredderz is a virtual band of metal legends and musicians that has emerged from the depths of the metaverse to melt faces. With help from the Dark Lord, they aim to revolutionize metal. Fans can become crowd-sourced managers and vote on song choices, merch designs, and more. Members will receive exclusive content and priority access to future drops.<br/><br/> The Shredderz Access Pass is an evolving token that grants Day 1 Shredderz fans priority access to the Shredderz universe. Access Passes are dynamic NFTs that will transform to showcase new art and simultaneously reward fans as The Shredderz reach new streaming milestones on Spotify. \""},
    {title: "How Can A Generative Mint Support Audio?", content: "The Shredderz is a virtual band of metal legends and musicians that has emerged from the depths of the metaverse to melt faces. With help from the Dark Lord, they aim to revolutionize metal. Fans can become crowd-sourced managers and vote on song choices, merch designs, and more. Members will receive exclusive content and priority access to future drops.<br/><br/> The Shredderz Access Pass is an evolving token that grants Day 1 Shredderz fans priority access to the Shredderz universe. Access Passes are dynamic NFTs that will transform to showcase new art and simultaneously reward fans as The Shredderz reach new streaming milestones on Spotify. \""}
]

export default function DropFAQ(props) {
    const appContext = useContext(AppContext);
    const [expanded, setExpanded] = useState(ROW);

    useEffect(() =>{
        appContext.onLayoutOptions({
            backgroundComponent: DropBackgroundVideo,
            footerComponent: DropFooter,
            video: "/images/drops/high.mp4"
        });
        return () => appContext.onLayoutReset();
    }, [])

    const onChange = (idx, event, state) => {
        const expandedUpdated = [...expanded];
        expandedUpdated[idx] = state;
        setExpanded(expandedUpdated);
    }

    return (
        <Stack spacing={3}>
            {ROW.map((row, idx) => {
                return (
                    <FilterContainer
                        onChange={(event, expanded) => onChange(idx, event, expanded)}
                        expanded={expanded[idx] === true}
                        defaultExpanded={false}
                        TransitionProps={{
                            mountOnEnter: true,
                            unmountOnExit: true,
                            timeout: { enter: 0, exit: 0 }
                        }}>
                        <Filter expandIcon={<ExpandMore />}>
                            <Typography variant="h5">
                                {row.title}
                            </Typography>
                        </Filter>
                        <AccordionDetails>{row.content}</AccordionDetails>
                    </FilterContainer>
                )
            })}
        </Stack>
    )
}

const FilterContainer = styled(Accordion)(({ theme }) => ({
    border: "1px solid grey",
    borderRadius: "20px !important",
    padding: 25,
    "&.MuiCollapse-root": {
        backgroundColor: "white !important"
    },
    "&.Mui-expanded": {
        margin: 0
    },
    "&. MuiAccordionSummary-content": {
        fontSize: "1.2rem !important",
    },
    "&:before": {
        backgroundColor: "transparent !important"
    }
}));

const Filter = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content": {
        fontSize: "1.4rem !important",
    },
    "&:hover": {
        backgroundColor: "rgb(41, 31, 55) !important",
        transition: "color .3s ease-out",
        borderRadius: "12px !important"
    }
}));