import React, {useContext, useRef, useState} from 'react';
import {styled} from "@mui/styles";
import PlatformSpace from "../../components/platform/platformSpace";
import {NotificationsOutlined, Verified} from "@mui/icons-material";
import {isMobile} from "react-device-detect";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import DropsContext from "./dropsContext";

export default function DropsBannerTile(props) {
    const {image, video} = props;
    const videoRef = useRef();
    const containerRef = useRef(null);
    const dropsContext = useContext(DropsContext);
    const [hover, setHover] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState([
        {name: "DAYS", value: 0},
        {name: "HRS", value: 21},
        {name: "MINS", value: 41},
        {name: "SECS", value: 17}
    ])

    const onHoverIn = () => {
        setHover(true)
    }

    const onHoverOut = () => {
        setHover(false)
    }

    const setPlayBack = () => {
        videoRef.current.playbackRate = 0.8;
    };

    return (
        <TileContainer ref={containerRef} onMouseEnter={onHoverIn} onMouseLeave={onHoverOut}>
            <ImageContainer hover={hover}>
                {video &&
                    <React.Fragment>
                        <VideoPlayer ref={videoRef} onCanPlay={() => setPlayBack()} autoPlay loop muted width={window.innerWidth}>
                            <source src={`${process.env.PUBLIC_URL}${video}`} type="video/mp4"/>
                        </VideoPlayer>
                        <VideoOverlay />
                    </React.Fragment>
                }
                {image &&
                    <React.Fragment>
                        <Image src={image} />
                        <ImageOverlay/>
                    </React.Fragment>
                }
            </ImageContainer>
            <Content>
                <BrandLogo height={65} src={`${process.env.PUBLIC_URL}/images/gasgiant/logo.png`}/>
                <PlatformSpace height={30}/>
                <DropTitle>Intergalatic Planetary</DropTitle>
                <PlatformSpace height={10}/>
                <BrandTitle>
                    Gas Giant
                    <VerifiedIcon sx={{fontSize: "0.8rem"}}/>
                </BrandTitle>
            </Content>
            <Footer>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Stack direction="row" spacing={1}>
                            {timeRemaining.map((time) => {
                                return (
                                    <Countdown>
                                        <Stack spacing={1}>
                                            <Typography variant="body1" sx={{lineHeight: 1}}>{time.value}</Typography>
                                            <Typography variant="body2" sx={{lineHeight: 1}}>{time.name}</Typography>
                                        </Stack>
                                    </Countdown>
                                )
                            })}
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={1}>
                            <TabButton>
                                <NotificationsOutlined fontSize="medium" />
                            </TabButton>
                            <TabButton selected={hover} style={{minWidth: 140}}>
                                <Typography variant="button" onClick={() => dropsContext.onDrop("123")}>
                                    View drop
                                </Typography>
                            </TabButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Footer>
        </TileContainer>
    );
}

const Countdown = styled('div')(({  }) => ({
    padding: "10px 5px 5px",
    letterSpacing: 1,
    height: 60,
    width: 60,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
    fontSize: "0.8rem",
    backdropFilter: "blur(20px)",
    color: "white !important",
    borderRadius: 12,
    background: "rgba(255, 255, 255, 0.2)",
}));

const TabButton = styled(Button)(({ selected }) => ({
    minHeight: 60,
    textAlign: "center",
    cursor: "pointer",
    backdropFilter: "blur(20px)",
    color: "white !important",
    borderRadius: 12,
    background: "rgba(255, 255, 255, 0.2) !important",
    ...(selected && {
        transition: "background .15s ease-out",
        background: "rgba(255, 255, 255, 1) !important",
        color: "black !important"
    }),
}));

const Footer = styled('div')(({ theme }) => ({
    width: "100%",
    position: "absolute",
    zIndex: 1,
    top: "80%",
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 20
}));

const BrandLogo = styled('img')(({ theme }) => ({
    border: "1px solid white",
    borderRadius: 12
}));

const ImageOverlay = styled('div')(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100%",
    backgroundSize: "100%",
    //backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`
}));

const VerifiedIcon = styled(Verified)(({ theme }) => ({
    color: "rgb(32, 129, 226) !important",
    marginLeft: 5,
}));

const BrandTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.0rem",
    color: "white",
}));

const DropTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    color: "white",
}));

const Content = styled('div')(({ theme }) => ({
    width: "100%",
    position: "absolute",
    zIndex: 1,
    left: 40,
    top: isMobile ? "20%" : "15vh",
}));

const TileContainer = styled('div')(({ theme }) => ({
    zIndex: 2,
    position: "relative",
    cursor: "pointer",
    boxShadow: "rgba(183, 132, 255, 0.25) 0px 6px 25px 0px",
    borderRadius: 20,
    width: "100%",
    height: "40vh",
    overflow: "hidden",
}));

const Image = styled('img')(({ theme }) => ({
    //opacity: 0.99,
    width: "100vw",
    height: "60vh",
    objectFit: "cover"
}));

const ImageContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    borderRadius: "20px 20px 0 0",
    transition: ".25s ease-in-out",
    transform: hover ? "scale(1.035)" : "scale(1)",
    position: "relative",
}));

const VideoPlayer = styled('video')(({  }) => ({
    zIndex: -1,
    position: "absolute",
    opacity: 0.7
}));

const VideoOverlay = styled('div')(({  }) => ({
    width: "100%",
    height: "110%",
    zIndex: 0,
    position: "absolute",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundImage: `linear-gradient(0deg, rgba(41, 31, 55, 1),  rgba(41, 31, 55, 1), rgba(2, 2, 2, 1), rgba(2, 2, 2, 0.9), rgba(2, 2, 2, 0.1), rgba(2, 2, 2, 0.1))`
}));
//http://bideowego.com/css3-border-radius-transitions
//https://stackoverflow.com/questions/31693219/issue-while-using-transitions-opacity-change-overflow-hidden/31698580#31698580