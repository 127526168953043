import PublicLayout from "components/layout/publicLayout";
import React from "react";
import DropHome from "./dropHome";
import {DropContextProvider} from "./dropContext";

export default function DropIndex(props) {
    return (
        <PublicLayout>
            <DropContextProvider>
                <DropHome/>
            </DropContextProvider>
        </PublicLayout>
    );
}