import * as React from 'react';
import {useContext, useEffect, useRef} from 'react';
import {styled} from "@mui/styles";
import useWindowSize from "../../../components/hook/useWindowSize";
import AppContext from "../../../appContext";

export default function DropBackgroundVideo(props) {
    const {offset, video} = props;
    const appContext = useContext(AppContext);
    const videoRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            updateBackgroundSize();
        }, 50)
    }, [videoRef.current]);

    useWindowSize(() => {
        updateBackgroundSize();
    })

    const updateBackgroundSize = () => {
        if (videoRef) {
            appContext.onBackgroundSize({
                width: videoRef?.current?.getBoundingClientRect().width,
                height: videoRef?.current?.getBoundingClientRect().height
            })
        }
    }

    return (
        <React.Fragment>
            <VideoPlayer ref={videoRef} autoPlay loop muted width={window.innerWidth}>
                <source src={`${process.env.PUBLIC_URL}${video}`} type="video/mp4"/>
            </VideoPlayer>
        </React.Fragment>
    )
}

const VideoPlayer = styled('video')(({ offset }) => ({
    zIndex: -1,
    position: "absolute",
    opacity: 0.7,
    width: "100vw"
}));