import React, {useContext, useEffect} from "react";
import {PublicContextProvider} from "./publicContext";
import PlatformSpace from "../platform/platformSpace";
import AppContext from "../../appContext";
import {styled} from "@mui/styles";
import PublicCart from "./publicCart";
import useScrollOffset from "../hook/useScrollOffset";

export default function PublicLayout(props) {
    const {fullWidth, children} = props;
    const {layoutOptions} = useContext(AppContext);
    const offset = useScrollOffset();
    const BackgroundComponent = layoutOptions.backgroundComponent;
    const FooterComponent = layoutOptions.footerComponent;
    const HeaderComponent = layoutOptions.headerComponent;
    const {video, blur = 0, topMargin = 80} = layoutOptions;

    useEffect(()  => {
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundImage = "linear-gradient(to top, rgb(41, 31, 55), rgb(2, 2, 3))";
    }, [layoutOptions]);

    return (
        <React.Fragment>
            <PublicContextProvider>
                { layoutOptions.backgroundComponent &&
                    <BackgroundComponent offset={offset} video={video}/>
                }
                { layoutOptions.headerComponent &&
                    <React.Fragment>
                        <HeaderComponent offset={offset}/>
                    </React.Fragment>
                }
                <div style={{backdropFilter: `blur(${blur})`}}>
                    {layoutOptions.headerComponent && <PlatformSpace height={topMargin}/>}
                    <ChildrenContainer fullWidth={fullWidth}>
                        {children}
                    </ChildrenContainer>
                </div>
                { layoutOptions.footerComponent &&
                    <FooterComponent offset={offset}/>
                }
                { !layoutOptions.footerComponent &&
                    <PlatformSpace height={100}/>
                }
                <PublicCart/>
            </PublicContextProvider>
        </React.Fragment>
    );
}

const ChildrenContainer = styled('div')(({ fullWidth }) => ({
    margin: "auto",
}));