import React, {useContext} from "react";
import CheckoutContext from "./checkoutContext";
import {Stack} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";

const STEPS = [{
    nextButton: "Continue to shipping",
    backButton: "Return to cart",
},{
    nextButton: "Continue to payment",
    backButton: "Return to information",
},{
    nextButton: "Pay now",
    backButton: "Return to shipping",
}]

export default function CheckoutControls(props) {
    const {wizardStep, setWizardStep} = useContext(CheckoutContext);

    const onBack = () => {
        setWizardStep(wizardStep - 1);
    }

    const onNext = () => {
        setWizardStep(wizardStep + 1);
    }

    return (
        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
            <CartLink onClick={onBack}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={1}>
                    <BackArrow/>
                    <div>{STEPS[wizardStep - 1]?.backButton}</div>
                </Stack>
            </CartLink>
            <ContinueButton variant="contained" onClick={onNext}>
                {STEPS[wizardStep - 1]?.nextButton}
            </ContinueButton>
        </Stack>
    );
}

const BackArrow = styled(ArrowBackIos)(({ theme }) => ({
    fontSize: "0.8rem !important",
    color: "#5492D9FF !important",
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));