import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {BreederContextProvider} from "./breederContext";
import BreederHome from "./breederHome";

export default function BreederIndex(props) {
    return (
        <PublicLayout>
            <BreederContextProvider>
                <BreederHome/>
            </BreederContextProvider>
        </PublicLayout>
    );
}